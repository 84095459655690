import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DividerModule} from "primeng/divider";
import {AvatarModule} from "primeng/avatar";
import {InputTextModule} from "primeng/inputtext";
import {InputGroupModule} from "primeng/inputgroup";
import {ButtonDirective} from "primeng/button";
import {Inplace, InplaceModule} from "primeng/inplace";
import {FileUploadModule, UploadEvent} from "primeng/fileupload";
import {AuthService} from "../../../services/auth.service";
import {FelixUser} from "../../../models/user.model";
import {FormsModule} from "@angular/forms";
import {MessageService} from "primeng/api";
import {AccountService} from "../../../services/account.service";
import {Account} from "../../../models/account.model";
import {NgIf} from "@angular/common";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'app-profile-settings',
  standalone: true,
  imports: [
    DividerModule,
    AvatarModule,
    InputTextModule,
    InputGroupModule,
    ButtonDirective,
    InplaceModule,
    FileUploadModule,
    FormsModule,
    NgIf,
    TooltipModule
  ],
  templateUrl: './profile-settings.component.html',
  styleUrl: './profile-settings.component.css'
})
export class ProfileSettingsComponent implements OnInit {
  @ViewChild('profileFirstNameInPlace') profileFirstNameInPlace: Inplace | undefined;
  @ViewChild('profileLastNameInPlace') profileLastNameInPlace: Inplace | undefined;
  @ViewChild('accountNameInPlace') accountNameInPlace: Inplace | undefined;
  loading = false;
    userDetails: FelixUser | undefined;
    firstName: string = "";
    lastName: string = "";
    accountName: string = "";
    profileEditMode = false;
    account: Account | null = null;
    constructor(private authService: AuthService, private accountService: AccountService, private messageService: MessageService) {

    }

    ngOnInit() {
      this.authService.userDetails.subscribe((user) => {
        if (user) {
          this.authService.user$.subscribe((res) => {

          })
          this.userDetails = user;
          this.firstName = user.firstName;
          this.lastName = user.lastName;
        }
      });
      this.accountService.activeAccount.subscribe((account) => {
        if (account) {
          this.account = account;
          this.accountName = account.name;
        }
      })
    }

    saveProfile() {
      this.loading = true;
      let updates = {
        firstName: this.firstName,
        lastName: this.lastName
      };
      this.authService.updateProfile(updates).subscribe((res) => {
        this.loading = false;
        this.authService.getUserDetails().subscribe();
        //this.authService.refreshFirebaseUser();
        this.profileFirstNameInPlace?.deactivate();
        this.profileLastNameInPlace?.deactivate();
        this.accountNameInPlace?.deactivate();
        this.messageService.add({key: "global", severity: 'success', summary: 'Success', detail: 'Profile Updated', life: 3000 });
      })
    }

  onUpload(event: any) {
    this.authService.uploadProfileImage(event.currentFiles[0]).subscribe((res) => {
    })
  }

}
