import {Component, inject} from '@angular/core';
import {AvatarModule} from "primeng/avatar";
import {Button} from "primeng/button";
import {Auth, authState, signOut, User} from "@angular/fire/auth";
import {filter, Subscription} from "rxjs";
import {CommonModule} from "@angular/common";
import {AuthService} from "../../../services/auth.service";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterLink} from "@angular/router";
import {FelixUser} from "../../../models/user.model";
import {UnsubscriberService} from "../../../services/unsubscriber.service";
import {TagModule} from "primeng/tag";
import {environment} from "../../../../environments/environment";


@Component({
  selector: 'app-header',
  standalone: true,
  providers: [UnsubscriberService],
  imports: [
    AvatarModule,
    Button,
    CommonModule,
    RouterLink,
    TagModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  title = 'felix';
  private unsubService = inject(UnsubscriberService);
  private auth = inject(Auth);
  private router = inject(Router);
  private authService = inject(AuthService);
  user: FelixUser | null = null;
  currentUrl: string = "";
  isLoggedIn = false;
  constructor() {
    this.authService.isLoggedIn().pipe(this.unsubService.takeUntilDestroy).subscribe((res) => {
      this.isLoggedIn = res;
    })
    this.authService.userDetails.pipe(this.unsubService.takeUntilDestroy).subscribe((user) => {
      if (user) {
        this.user = user;
      } else {
        this.user = null;
      }
    })
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.currentUrl = event.url;
      // this only fires for `NavigationStart` and no other events
    });
  }

  logout() {
    this.authService.logout();
  }

  protected readonly environment = environment;
}
