<div>
  <p-table [value]="accountRoles" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header">
      <tr>
        <th>User</th>
        <th>Date Added</th>
        <th>Role</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-accountRole>
      <tr>
        <td width="20%">{{ accountRole.user.email }}</td>
        <td width="15%" class="text-sm">{{ accountRole.createdAt | date: 'short' }}</td>
        <td width="15%"><p-tag [severity]="accountRole.accountRoleType.name === 'admin' ? 'warning' : 'info'" [value]="accountRole.accountRoleType.name | titlecase"/></td>
        <td width="25%">
          <p-button (onClick)="prepareModal('edit', accountRole)" icon="pi pi-pencil" severity="secondary"></p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="caption">
      <div class="text-right w-full">
        <p-button
          pRipple
          label="Add User"
          (click)="mode = 'add';showAddEditModal = true"
          (onClick)="prepareModal('add')"
          class="p-button-success ml-4">
        </p-button>
      </div>
    </ng-template>
  </p-table>
</div>

<p-dialog [header]="this.mode === 'edit' ? 'Edit User Role' : 'Add User'" [draggable]="false" [modal]="true" [(visible)]="showAddEditModal" [style]="{ width: '25rem' }">
  <span *ngIf="this.mode === 'add'" class="p-text-secondary block mb-5">Add a new user to the account.</span>
  <span *ngIf="this.mode === 'edit'" class="p-text-secondary block mb-5">Edit the user's role for the account.</span>

  <div class="flex align-items-center gap-3 mb-5">
    <label for="email" class="font-semibold w-6rem">Email</label>
    <input [disabled]="mode === 'edit'" [(ngModel)]="editEmailAddress" pInputText id="email" class="flex-auto" autocomplete="off" />
  </div>
  <div class="flex align-items-center gap-3 mb-3">
    <label for="roletype" class="font-semibold w-6rem">Role</label>
    <p-dropdown id="roletype" optionLabel="name" [(ngModel)]="editAccountRoleType" appendTo="body" [options]="roleTypes"></p-dropdown>
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button *ngIf="mode !== 'edit'" label="Cancel" severity="secondary" (onClick)="showAddEditModal = false" />
    <p-button *ngIf="mode === 'edit'" [loading]="deleteLoading" [label]="deleteLoading ? 'Deleting' : 'Delete'" [outlined]="true" severity="danger" (onClick)="confirmDelete($event)" />
    <p-confirmPopup #confirmPopupRef>
      <ng-template pTemplate="content" let-message>
        <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border p-3 mb-3 text-r">
          <i [class]="message.icon" class="text-6xl text-primary-500"></i>
          <p>{{ message.message }}</p>
        </div>
      </ng-template>
    </p-confirmPopup>
    <p-button [disabled]="!editAccountRoleType || !editEmailAddress" [loading]="loading" label="Save" (onClick)="submit()" />
  </div>
</p-dialog>
