import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {AccountService} from "../services/account.service";
import {filter} from "rxjs";
import {map, take} from "rxjs/operators";
import {Subscription, SubscriptionStatus} from "../models/subscription.model";

export function activeSubscriptionGuard(
  redirectRoute: string
): CanActivateFn {
  return () => {
    const accountService: AccountService = inject(AccountService);
    const router: Router = inject(Router);
    const isTrialExpired = (subscription: Subscription) => {
      return new Date().getTime() > new Date(subscription.currentPeriodEnd).getTime();
    }
    return accountService.activeAccount.pipe(filter((account => account !== null)), take(1),map((account) => {
      if(account?.subscriptions.some(s => s.status === SubscriptionStatus.ACTIVE || (s.status === SubscriptionStatus.TRIALING && !isTrialExpired(s)))) {
        return true
      } else if (account?.subscriptions.some(s => s.status === SubscriptionStatus.PAST_DUE)) {
        return router.createUrlTree(['settings'], {queryParams: {"tab": "billing"}});
      } else {
        return router.createUrlTree([redirectRoute])
      }
    }));
  };
}
