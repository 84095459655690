<div class="p-4">
  <div *ngIf="subscription?.status === 'TRIALING' && showTrialNotification">
    <p-messages severity="info" [closable]="true" [(value)]="messages" (onClose)="closeTrialNotification()">
    </p-messages>
  </div>
  <div class="flex justify-content-between">
    <p-breadcrumb
      class="max-w-full"
      [home]="home" />
    <div class="flex">
      <p-selectButton
        [options]="entityViewOptions"
        [(ngModel)]="entityViewValue"
        [allowEmpty]="false"
        (onChange)="updateDefaultView($event)"
        optionLabel="label"
        optionValue="value" />
      <p-button
        pRipple
        label="New Entity"
        (onClick)="sidebarVisible = true"
        class="p-button-success ml-4">
      </p-button>

    </div>
  </div>
  <div class="p-3">
    <p-table *ngIf="entityViewValue === 'list'" [loading]="loadingEntities" [showLoader]="false" [value]="entities" [tableStyle]="{ 'min-width': '50rem' }" selectionMode="single" >
      <ng-template pTemplate="header">
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Incorporation Date</th>
          <th>EIN</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr *ngFor="let row of [1,2,3,4,5]">
          <td><p-skeleton></p-skeleton></td>
          <td><p-skeleton></p-skeleton></td>
          <td><p-skeleton></p-skeleton></td>
          <td><p-skeleton></p-skeleton></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-entity>
        <tr class="cursor-pointer" [routerLink]="'../entities/' + entity.entityId">
          <td>{{ entity.name }}</td>
          <td>{{ entity.entityType }}</td>
          <td>{{ entity.incorporationDate | date : 'M/dd/YYYY' }}</td>
          <td>{{ entity.ein }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
      </ng-template>
    </p-table>
    <div *ngIf="!entities.length && !loadingEntities" class="border-round p-3 text-center">
      <img style="max-width: 550px;" class="border-round" src="entity_bgnd.png"/>
      <p *ngIf="entityViewValue === 'list'" class="text-sm text-color-secondary">Add, track and manage all of your entities and their relationships to each other. Simply click "New Entity" in the top right to get started.</p>
      <p *ngIf="entityViewValue === 'map'" class="text-sm text-color-secondary">Visualize the relationships between your entities with this interactive map view. Simply click "New Entity" in the top right to get started.</p>
      <p-button (onClick)="sidebarVisible = true" label="Add Your First Entity"></p-button>
    </div>
    <app-entity-map #entityMap *ngIf="entityViewValue === 'map' && entities.length" [_data]="entities"></app-entity-map>
  </div>
</div>
<p-sidebar [blockScroll]="true" [(visible)]="sidebarVisible" position="right" [style]="{'min-width': '33vw'}">
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <i class="pi pi-building" style="font-size: 1.5rem"></i>
      <!--      <p-avatar-->
      <!--        image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"-->
      <!--        shape="circle" />-->
      <h2 class="font-bold">
        New Entity
      </h2>
    </div>
  </ng-template>
  <app-add-edit-entity (complete)="entityAddedHandler($event)"></app-add-edit-entity>
</p-sidebar>
