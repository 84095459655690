<div class="p-4">
  <p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel header="Profile">
      <app-profile-settings></app-profile-settings>
    </p-tabPanel>
    <p-tabPanel header="User Management">
      <app-user-management-settings></app-user-management-settings>
    </p-tabPanel>
    <p-tabPanel header="Billing">
      <app-subscription-settings></app-subscription-settings>
    </p-tabPanel>
  </p-tabView>
</div>
