<div class="p-4">
  <h3 class="mb-3 text-black-alpha-90">Sent Document Logs</h3>
  <p-table [loading]="loading" [scrollable]="true" scrollHeight="80vh"  styleClass="p-datatable-striped" [showLoader]="false" [value]="documentSendLogs">
    <ng-template pTemplate="header">
      <tr>
        <th>Date</th>
        <th>Entity</th>
        <th>Sent By</th>
        <th>Sent To</th>
        <th>Documents</th>
        <th>Folders</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-log>
      <tr>
        <td width="15%"><span class="text-sm font-bold text-color-secondary">{{log.sentAt | date: 'short'}}</span></td>
        <td width="15%">{{log.entityName}}</td>
        <td>{{log.sentBy}}</td>
        <td><p-tag styleClass="mr-1 mt-1" severity="secondary" *ngFor="let email of log.destinationEmails" [value]="email"></p-tag></td>
        <td>
          <p-chip *ngIf="log.documentNames?.length" (click)="documentOverlay.toggle($event)" styleClass="cursor-pointer">
            <span class="pi pi-folder"></span>
            <span class="mx-2">Documents</span>
            <p-badge [value]="log.documentNames.length" />
          </p-chip>
          <p-overlayPanel #documentOverlay>
            <p-scrollPanel [style]="{ width: '100%', 'height': '125px' }">
              <div class="flex flex-column gap-1">
                <p class="mt-0 mb-1 pb-2 border-bottom-1 border-gray-200" *ngFor="let doc of log.documentNames">{{doc}}</p>
              </div>
            </p-scrollPanel>
          </p-overlayPanel>
        </td>
        <td>
          <p-chip *ngIf="log.folderNames?.length" (click)="folderOverlay.toggle($event)" styleClass="cursor-pointer">
            <span class="pi pi-folder"></span>
            <span class="mx-2">Folders</span>
            <p-badge [value]="log.folderNames.length" />
          </p-chip>
          <p-overlayPanel #folderOverlay>
            <p-scrollPanel [style]="{ width: '100%', 'height': '125px' }">
              <div class="flex flex-column gap-1">
                <p class="mt-0 mb-1 pb-2 border-bottom-1 border-gray-200" *ngFor="let folder of log.folderNames">{{folder}}</p>
              </div>
            </p-scrollPanel>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr *ngFor="let row of [1,2,3,4,5]">
        <td><p-skeleton></p-skeleton></td>
        <td><p-skeleton></p-skeleton></td>
        <td><p-skeleton></p-skeleton></td>
        <td><p-skeleton></p-skeleton></td>
        <td><p-skeleton></p-skeleton></td>
        <td><p-skeleton></p-skeleton></td>
      </tr>
    </ng-template>
  </p-table>
</div>
