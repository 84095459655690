import {ErrorHandler, Inject, Injectable, Injector} from '@angular/core';
import {environment} from "../../environments/environment";
import {MessageService} from "primeng/api";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  constructor(@Inject(Injector) private readonly injector: Injector) {
    super();
  }

  private get messageService(): MessageService {
    return this.injector.get(MessageService);
  }

  // @ts-ignore
  handleError(err) {
    // Custom error handling logic
    //console.error('ErrorService.handleError, err', err);
    if (err instanceof EvalError) {
      // console.log('Error Type=', err.name);
    } else if (err instanceof RangeError) {
      // console.log('Error Type=', err.name);
    } else if (err instanceof ReferenceError) {
      // console.log('Error Type=', err.name);
    } else if (err instanceof SyntaxError) {
      // console.log('Error Type=', err.name);
    } else if (err instanceof TypeError) {
      // console.log('Error Type=', err.name);
    } else if (err instanceof URIError) {
      // console.log('Error Type=', err.name);
    } else if (err instanceof ErrorEvent) {
      // console.log('Error Type=', err.name);
    } else if (err instanceof HttpErrorResponse) {

      this.messageService.clear();
      this.messageService.add({ key: 'global', severity: 'error', summary: 'Error', life: 3000, detail: err.error.error });

    } else if (err.code && typeof err.code === "string" && err.code.startsWith('auth/')) {
      //Firebase auth error
      this.messageService.clear();
      this.messageService.add({ key: 'global', severity: 'error', summary: 'Error', life: 3000, detail: this.interpretFirebaseError(err.code) });
      return;
    } else {
      // console.log('Error Type=', err.name);
      throw err;
    }
    // if (environment.DEBUG_DIALOGS) {
    //   this._ui.notifyUserShowConfirmDialog({
    //     noCancelButton: true,
    //     messageHtml: `<span>${err.message}</span><pre>${err.stack}</pre>`,
    //     title: `Error: ${err.name}`,
    //     confirmButtonText: 'OK'
    //   });
    // }
  }

  interpretFirebaseError(errCode: string) {
    switch(errCode) {
      case "auth/invalid-credential":
        return "Password is incorrect";
      case "auth/too-many-requests":
        return "Access to this account has been temporarily disabled due to many failed login attempts. Try again later."
      default:
        return "There was an error. Try again or reach out to support."
    }
  }

}
