import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbModule} from "primeng/breadcrumb";
import {Button} from "primeng/button";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {PrimeTemplate} from "primeng/api";
import {Ripple} from "primeng/ripple";
import {SelectButtonModule} from "primeng/selectbutton";
import {TableModule} from "primeng/table";
import {AddEditEntityComponent} from "../add-new-entity/add-edit-entity.component";
import {SidebarModule} from "primeng/sidebar";
import {Entity, EntityHomeDefaultView} from "../../models/entity.model";
import {EntityService} from "../../services/entity.service";
import {FormsModule} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {EntityMapComponent} from "./entity-map/entity-map.component";
import {DividerModule} from "primeng/divider";
import {SkeletonModule} from "primeng/skeleton";
import {ActivatedRoute} from "@angular/router";
import {AccountService} from "../../services/account.service";
import {MessagesModule} from "primeng/messages";
import {Subscription, SubscriptionStatus} from "../../models/subscription.model";
import {UnsubscriberService} from "../../services/unsubscriber.service";
import {getRemainingDays} from "../../utils/helpers";
import {EntityTypeRename} from "../../pipes/entityTypeRename";

@Component({
  selector: 'app-entities-home',
  standalone: true,
  imports: [
    BreadcrumbModule,
    Button,
    DatePipe,
    PrimeTemplate,
    FormsModule,
    Ripple,
    SelectButtonModule,
    TableModule,
    AddEditEntityComponent,
    SidebarModule,
    EntityMapComponent,
    NgIf,
    DividerModule,
    SkeletonModule,
    NgForOf,
    MessagesModule,
    EntityTypeRename
  ],
  providers: [UnsubscriberService],
  templateUrl: './entities-home.component.html',
  styleUrl: './entities-home.component.css'
})
export class EntitiesHomeComponent implements OnInit {
  @ViewChild('entityMap') entityMap: ElementRef | undefined;
  entities: Entity[] = [];
  loadingEntities = true;
  breadcrumItems = [
    { label: '' }
  ];
  home = { label: "", routerLink: '/' };
  sidebarVisible = false;
  entityViewValue: EntityHomeDefaultView = EntityHomeDefaultView.list;
  entityViewOptions: any[] = [{ label: 'List View', value: 'list' },{ label: 'Map View', value: 'map' }];
  messages = [{ severity: 'info', detail: 'You have 27 days remaining in your free trial of Pro features.' }];
  localStorageNotificationKey = "show-trial-notification";
  showTrialNotification = true;
  subscription: Subscription | undefined;

  constructor(private entityService: EntityService, private route: ActivatedRoute, private accountService: AccountService, private unsubService: UnsubscriberService) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(this.unsubService.takeUntilDestroy).subscribe((params) => {
      if (params && params['view']) {
        const view = params['view'];
        if (view === EntityHomeDefaultView.list) {
          this.entityViewValue = EntityHomeDefaultView.list
        }
        if (view === EntityHomeDefaultView.map) {
          this.entityViewValue = EntityHomeDefaultView.map;
        }
        if (view === "new") {
          this.sidebarVisible = true;
        }
      }
    });

    this.accountService.activeAccount.pipe(this.unsubService.takeUntilDestroy).subscribe((account) => {
      if (account && account.subscriptions.length) {
        //this.getEntities();
        this.subscription = account.subscriptions.find((s) => s.status !== SubscriptionStatus.CANCELED);
        if (this.subscription?.status === SubscriptionStatus.TRIALING) {
          this.messages[0].detail = `You have ${getRemainingDays(this.subscription.currentPeriodEnd)} days remaining in your free trial of Pro features.`;
          this.checkIfShouldDisplayTrialBanner();
        }
      }
    })

    this.entityService.entities.asObservable().pipe(this.unsubService.takeUntilDestroy).subscribe((entities: Entity[]) => {
      if (this.entityService.entitiesLoaded) {
        this.loadingEntities = false;
      } else {
        this.loadingEntities = true;
      }
      this.entities = entities;
      this.entityViewValue = this.entityService.getDefaultEntityHomeView();
    });

    this.checkIfShouldDisplayTrialBanner();
  }

  checkIfShouldDisplayTrialBanner() {
    try {
      if (localStorage.getItem(this.localStorageNotificationKey) === "false") {
        this.showTrialNotification = false;
      }
    } catch(err) {
      console.error(err);
    }
  }

  getEntities(): void {
    this.entityService.getAllEntities();
  }

  entityAddedHandler(data: {entity: Entity, closeModalOnFinish: boolean}) {
    this.entities.push(data.entity);
    if (data.closeModalOnFinish) {
      this.sidebarVisible = false;
    }
  }

  updateDefaultView(event) {
    this.entityService.setDefaultEntityHomeView(event.value);
  }

  closeTrialNotification() {
    try {
      localStorage.setItem(this.localStorageNotificationKey, "false");
    } catch(err) {
      console.error("local storage not available")
    }
  }
  protected readonly SubscriptionStatus = SubscriptionStatus;
}
