<div class="flex justify-content-between align-items-center px-3">
    <div class="flex justify-content-center align-items-center">
      <img routerLink="" class="h-3rem cursor-pointer" src="felix_logo_white.png"/>
      <p-tag *ngIf="!environment.production" styleClass="ml-2">DEV ENVIRONMENT</p-tag>
    </div>
  <div class="flex align-items-center cursor-pointer">
    <div  [routerLink]="'/settings'" *ngIf="isLoggedIn" class="mr-3 flex align-items-center mt-1">
      <p-avatar
        *ngIf="user?.profileImage"
        [image]="user?.profileImage"
        shape="circle"
        styleClass="mr-2 bg-gray-200 text-black-alpha-90"  />
      <p-avatar
        *ngIf="!user?.profileImage"
        icon="pi pi-user"
        shape="circle"
        styleClass="mr-2 bg-gray-200 text-black-alpha-90"  />
      <span>{{user?.firstName}} {{user?.lastName}}</span>
    </div>
    <div *ngIf="!isLoggedIn && !currentUrl.includes('link')" class="mr-3">
      <span class="p-link" [routerLink]="'/register'">Sign Up</span>
    </div>
    <p-button *ngIf="isLoggedIn" (click)="logout()" label="Log Out" styleClass="text-white" [outlined]="true" />
  </div>
</div>
