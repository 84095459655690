import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileUploadModule} from "primeng/fileupload";
import {BadgeModule} from "primeng/badge";
import {MessageService, PrimeNGConfig} from "primeng/api";
import {CommonModule} from "@angular/common";
import {CascadeSelectModule} from "primeng/cascadeselect";
import {FormsModule} from "@angular/forms";
import {TooltipModule} from "primeng/tooltip";
import {TreeSelectModule} from "primeng/treeselect";
import {DocumentService} from "../../../services/document.service";
import {determineBaseTypes} from "@angular/cdk/schematics";

@Component({
  selector: 'app-documents-upload',
  standalone: true,
  imports: [
    FileUploadModule,
    BadgeModule,
    CommonModule,
    CascadeSelectModule,
    FormsModule,
    TooltipModule,
    TreeSelectModule
  ],
  templateUrl: './documents-upload.component.html',
  styleUrl: './documents-upload.component.css'
})
export class DocumentsUploadComponent {
  files = [];
  totalSize : number = 0;
  selectedFolder: any;
  totalSizePercent : number = 0;
  folderAssignments: any = {};
  @Input() entityId!: number;
  @Input() folders: any = [];
  @Output() documentsUploaded: EventEmitter<any> = new EventEmitter<any>();
  constructor(private config: PrimeNGConfig, private documentService: DocumentService) {

  }

  choose(event, callback) {
    callback();
  }

  onRemoveTemplatingFile(event, file, removeFileCallback, index) {
    removeFileCallback(event, index);
    this.totalSize -= parseInt(this.formatSize(file.size));
    this.totalSizePercent = this.totalSize / 10;
  }

  onClearTemplatingUpload(clear) {
    clear();
    this.totalSize = 0;
    this.totalSizePercent = 0;
  }

  onTemplatedUpload() {
    //this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
  }

  onSelectedFiles(event) {
    this.files = event.currentFiles;
    this.files.forEach((file: any) => {
      this.totalSize += parseInt(this.formatSize(file.size));
    });
    this.totalSizePercent = this.totalSize / 10;
  }

  updateDocumentFolderSelection(event, fileName) {
    this.folderAssignments[fileName] = event.node.data.folderId;
  }

  uploadFiles(event) {
    this.documentService.uploadDocuments(({entityId: this.entityId, documents: this.files, folderAssignments: this.folderAssignments})).subscribe((res) => {
      this.documentsUploaded.emit(res);
    });
  }

  formatSize(bytes) {
    const k = 1024;
    const dm = 3;
    const sizes = this.config.translation.fileSizeTypes;
    if (bytes === 0) {
      return `0 ${sizes![0]}`;
    }

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

    return `${formattedSize} ${sizes![i]}`;
  }
}
