import {Entity} from "./entity.model";
import {User} from "@angular/fire/auth";
import {Subscription} from "./subscription.model";

export interface Account {
  accountId: number;
  name: string;
  entities: Entity[];
  accountOwnerId: number;
  accountOwner: User;
  parentAccountId: number;
  parentAccount: Account;
  subAccounts: Account[];
  accountRoles: AccountRole[]
  accountType: any;
  subscriptions: Subscription[];
}

export interface AccountRole {
  accountRoleId: string;
  userId: number;
  user: User;
  accountId: number;
  account: Account;
  accountRoleType: any;
  accountRoleTypeId: number;
}

export const AccountRoleTypes = [
  {
    name: "Viewer",
    id: 2
  },
  {
    name: "Admin",
    id: 1
  }
]

export interface AccountRoleType {
  name: string;
  id: number;
}

export interface UpdateAccountRoleRequest {
  email: string;
  accountRoleTypeId: number;
}
