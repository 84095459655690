import {AbstractControl, FormGroup} from '@angular/forms';

// custom validator to check that a date is valid and in the past
export function pastDateValidator(control: AbstractControl) {
  if (control?.value) {
    const today = new Date();
    const dateToCheck = new Date(control.value);
    if (isNaN(dateToCheck.getTime())) {
      return {'Invalid date': true}
    }

    if (dateToCheck > today) {
      return {'Invalid date': true}
    }
  }
  return null;
}
