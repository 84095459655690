import {AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {Button} from "primeng/button";
import {TreeTable} from "primeng/treetable";
import {AuthService} from "../../../services/auth.service";
import {BillingService} from "../../../services/billing.service";
import {Subscription, SubscriptionStatus} from "../../../models/subscription.model";
import {AccountService} from "../../../services/account.service";
import {UnsubscriberService} from "../../../services/unsubscriber.service";
import {DatePipe, NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-subscription-settings',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    Button,
    DatePipe,
    NgIf,
    RouterLink
  ],
  providers: [UnsubscriberService],
  templateUrl: './subscription-settings.component.html',
  styleUrl: './subscription-settings.component.css'
})
export class SubscriptionSettingsComponent implements OnInit, AfterViewInit {
  @ViewChild('pricingTable') pricingTable: ElementRef | undefined;
  loading = false;
  unsubService = inject(UnsubscriberService);
  subscription: Subscription | undefined;
  constructor(private authService: AuthService, private billingService: BillingService, private accountService: AccountService, ) {
  }

  ngOnInit() {
    this.accountService.activeAccount.pipe(this.unsubService.takeUntilDestroy).subscribe((account) => {
      if (account && account.subscriptions.length) {
        this.subscription = account.subscriptions.find((s) => s.status !== SubscriptionStatus.CANCELED);
      }
    })
  }

  ngAfterViewInit() {
    this.authService.userDetails.subscribe((user) => {
      if (user) {
        this.pricingTable?.nativeElement.setAttribute('customer-email', user.email);
        this.pricingTable?.nativeElement.setAttribute('client-reference-id', user.userId);
      }
    })
  }

  goToBillingPortal() {
    this.loading = true;
    this.billingService.createBillingSession().subscribe((session) => {
      if (session) {
        window.location.href=session.url;
      }
      this.loading = false;
    })
  }

  protected readonly SubscriptionStatus = SubscriptionStatus;
}
