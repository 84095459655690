<div class="h-full">
<div class="grid grid-nogutter nested-grid flex-column">
  <div id="mainHeader" class="col-12">
    <div class="bg-gray-100 bg-primary flex-grow-0 p-1">
      <app-header></app-header>
    </div>
  </div>
  <div id="mainContainer" class="col-12 relative flex-grow-1">
    <div class="grid grid-nogutter h-full">
      <div *ngIf="isLoggedIn" style="width:275px" class="col-fixed bg-gray-50 h-full">
        <div class="p-4 h-full">
          <app-side-menu></app-side-menu>
        </div>
      </div>
      <div class="col h-full overflow-scroll">
          <router-outlet></router-outlet>
      </div>
    </div>
        <app-loader *ngIf="showLoader"></app-loader>
  </div>
</div>
<p-toast position="bottom-center" [life]="1000000" key="global" />
</div>
