import {Component, OnInit} from '@angular/core';
import {CardModule} from "primeng/card";
import {Button} from "primeng/button";
import {SecurelinkService} from "../../services/securelink.service";
import {ActivatedRoute} from "@angular/router";
import {SecureLink} from "../../models/securelink.model";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {DividerModule} from "primeng/divider";
import {InplaceModule} from "primeng/inplace";
import {FileSizePipe} from "../../pipes/fileSize.pipe";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-secure-link-landing-page',
  standalone: true,
  imports: [
    CardModule,
    Button,
    NgIf,
    DividerModule,
    NgForOf,
    InplaceModule,
    FileSizePipe,
    DatePipe,
    CdkCopyToClipboard
  ],
  templateUrl: './secure-link-landing-page.component.html',
  styleUrl: './secure-link-landing-page.component.css'
})
export class SecureLinkLandingPageComponent implements OnInit {
  secureLinkToken: string | null;
  expiredLink = false;
  secureLinkDetails: SecureLink | undefined;
  constructor(private route: ActivatedRoute, private secureLinkService: SecurelinkService) {
    this.secureLinkToken = this.route.snapshot.paramMap.get('token');
  }

  ngOnInit() {
    if (this.secureLinkToken) {
      this.secureLinkService.getSecureLinkDetailsByToken(this.secureLinkToken).subscribe((res) => {
        if (res === "expired") {
          this.expiredLink = true;
        } else {
          this.secureLinkDetails = res;
        }
      })
    }
  }

  downloadDocument(doc: any) {
    this.secureLinkService.downloadSecureLinkDocument(this.secureLinkToken!, doc.documentId).subscribe((res: any) => {
      let dataType = res.type;
      let binaryData: any = [];
      binaryData.push(res);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      downloadLink.setAttribute('download', doc.name);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    })
  }

}
