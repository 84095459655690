import { PipeTransform, Pipe } from '@angular/core';
import {
  AUDIOMIMETYPES,
  IMAGEMIMETYPES, PDF_MIMETYPE, POWERPOINTMIMETYPES,
  SPREADSHEETMIMETYPES,
  VIDEOMIMETYPES,
  WORDMIMETYPES
} from "../constants/mimeTypes";
import {EntityType} from "../models/entity.model";

@Pipe({ name: 'entityTypeRename', standalone: true })
export class EntityTypeRename implements PipeTransform {
  transform(typeString: string): string {
    if (EntityType.CORPORATION === typeString) {
      return 'Corporation';
    } else if (EntityType.S_CORPORATION === typeString) {
      return 'S-Corporation';
    } else if (EntityType.LIMITED_LIABILITY_COMPANY === typeString) {
      return 'LLC';
    } else if (EntityType.SOLE_PROPRIETORSHIP === typeString) {
      return 'Sole Proprietorship';
    } else if (EntityType.PARTNERSHIP === typeString) {
      return 'Partnership';
    } else {
      return typeString
    }
  }
}
