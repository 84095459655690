import {Component, OnInit} from '@angular/core';
import {TableModule} from "primeng/table";
import {DatePipe, NgIf, TitleCasePipe, UpperCasePipe} from "@angular/common";
import {TagModule} from "primeng/tag";
import {Button} from "primeng/button";
import {AuthService} from "../../../services/auth.service";
import {AccountService} from "../../../services/account.service";
import {Account, AccountRole, AccountRoleType, AccountRoleTypes} from "../../../models/account.model";
import {DialogModule} from "primeng/dialog";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {Ripple} from "primeng/ripple";
import {FormsModule} from "@angular/forms";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-user-management-settings',
  standalone: true,
  imports: [
    TableModule,
    DatePipe,
    TagModule,
    UpperCasePipe,
    TitleCasePipe,
    Button,
    DialogModule,
    DropdownModule,
    InputTextModule,
    Ripple,
    FormsModule,
    NgIf,
    ConfirmPopupModule
  ],
  templateUrl: './user-management-settings.component.html',
  styleUrl: './user-management-settings.component.css'
})
export class UserManagementSettingsComponent implements OnInit {
  accountRoles: AccountRole[] = [];
  showAddEditModal = false;
  roleTypes = AccountRoleTypes;
  mode: "add" | "edit" = "add";
  activeAccount: Account | undefined;
  editEmailAddress: string = "";
  editAccountRole: AccountRole | undefined;
  editAccountRoleType: AccountRoleType | undefined;
  loading = false;
  deleteLoading = false;
  constructor(private accountService: AccountService, private confirmationService: ConfirmationService) {}

  ngOnInit() {
    this.editAccountRoleType = this.roleTypes[0];
    this.accountService.activeAccount.subscribe((account) => {
      if (account) {
        this.activeAccount = account;
        this.accountRoles = account?.accountRoles
      }
    })
  }

  confirmDelete(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Please confirm, this action cannot be undone.',
      icon: 'pi pi-exclamation-circle text-red-100',
      acceptIcon: 'pi pi-check mr-1',
      rejectIcon: 'pi pi-times mr-1',
      acceptLabel: 'Confirm',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm p-button-secondary',
      acceptButtonStyleClass: 'p-button-sm p-button-danger',
      accept: () => {
        this.deleteAccountRole();
        //this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
      },
      reject: () => {
        //this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
      }
    });
  }

  deleteAccountRole() {
    this.deleteLoading = true;
    this.accountService.deleteAccountRole(this.activeAccount!.accountId, this.editAccountRole!.accountRoleId).subscribe((res) => {
      this.accountService.getAccount(this.activeAccount!.accountId);
      this.deleteLoading = false;
      this.showAddEditModal = false;
    })
  }

  prepareModal(mode, accountRole?: AccountRole) {
    this.mode = mode;
    this.editAccountRole = accountRole;
    this.editEmailAddress = "";
    this.editAccountRoleType = this.roleTypes[0];
    if (accountRole) {
      this.editEmailAddress = accountRole.user.email!;
      this.editAccountRoleType = this.roleTypes.find(r => r.id === accountRole.accountRoleTypeId);
    }
    this.showAddEditModal = true;
  }

  submit() {
    this.loading = true;
    if (this.editEmailAddress && this.editAccountRoleType) {
      this.accountService.createUpdateAccountRole(
        this.activeAccount!.accountId,
        {email: this.editEmailAddress, accountRoleTypeId: this.editAccountRoleType!.id})
        .subscribe((accountRole) => {
          this.accountService.getAccount(this.activeAccount!.accountId);
          this.loading = false;
          this.showAddEditModal = false;
        })
    }
  }
}
