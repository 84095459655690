import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Button} from "primeng/button";
import {EditorModule} from "primeng/editor";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {NgIf} from "@angular/common";
import {ActivityService} from "../../../services/activity.service";
import {Note, NoteCreateRequest} from "../../../models/activity.model";
import {SafeHtmlPipe} from "../../../pipes/safeHtml.pipe";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-notes-editor',
  standalone: true,
  imports: [
    Button,
    EditorModule,
    FormsModule,
    InputTextModule,
    NgIf,
    SafeHtmlPipe,
    ConfirmPopupModule
  ],
  templateUrl: './notes-editor.component.html',
  styleUrl: './notes-editor.component.css'
})
export class NotesEditorComponent {
  loading = false;
  deleting = false;
  noteText: string = "";
  noteTitle: string = "";
  @Output()
  onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSave: EventEmitter<NoteCreateRequest> = new EventEmitter<NoteCreateRequest>();
  viewOnlyMode = false;
  _note: Note | undefined;
  @Input() entityId: number | undefined;
  @Input()
  set note(_note: Note) {
    if (_note) {
      this._note = _note;
      this.noteText = _note.text!
      this.noteTitle = _note.title!;
      this.viewOnlyMode = true;
    }
  }


  constructor(private activityService: ActivityService, private confirmationService: ConfirmationService) {
  }

  closeEditor() {
    this._note = undefined;
    this.viewOnlyMode = false;
    this.noteText = "";
    this.noteTitle = "";
    this.onClose.emit();
  }

  confirmDeleteNote(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure you want to delete this note?',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-sm',
      accept: async () => {
        await this.deleteNote();
      },
      reject: () => {

      }
    });
  }

  async deleteNote() {
    this.deleting = true;
    await this.activityService.deleteActivity(this._note!.entityId, this._note!.activityId).subscribe((res) => {
      this.onClose.emit();
    })
  }

  saveNote() {
    if (this.noteText && this.noteTitle) {
      this.loading = true;
      this.activityService.createActivity({
        entityId: this.entityId!,
        note: {
          text: this.noteText,
          title: this.noteTitle,
        }
      }).subscribe((res) => {
        this.noteText = "";
        this.noteTitle = "";
        this.loading = false;
        this.onSave.emit();
      })
    }
  }
}
