<p-scrollPanel [style]="{ width: '100%'}">
  <form [formGroup]="sendDocumentsForm" (ngSubmit)="submit()">
    <div class="flex flex-column gap-2">
      <div>
        <div class="flex flex-column gap-2 mr-4">
          <label for="emailAddresses" class="font-bold">Email Addresses</label>
          <small>
            Comma separated list of email addresses to send the documents to.
          </small>
          <div class="p-fluid">
          <p-chips inputId="emailAddresses" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" id="emailAddresses" formControlName="emailAddresses" [allowDuplicate]="false" (onAdd)="validateEmail($event)" separator="," />
          </div>
        </div>
        <div class="flex flex-column gap-2 mr-4 mt-4">
          <label for="message" class="font-bold">Optional Message</label>
          <small>
            Include a message to the receivers of the documents.
          </small>
          <textarea
            id="message"
            rows="5"
            cols="30"
            [placeholder]="placeholderMessage"
            pInputTextarea
            formControlName="message">
          </textarea>
        </div>
        <div class="flex flex-column gap-2 mt-4">
          <label class="font-bold">Select Documents</label>
          <small>
            Select the folders and documents that will be attached to the email.
          </small>
          <div *ngIf="articlesOfIncorporationDocument" class="flex align-items-center cursor-pointer">
            <div class="flex flex-column gap-2 mr-2 w-5 cursor-pointer">
              <div class="bg-gray-50 p-2 border-round ml-1 cursor-pointer" [ngClass]="includeIncorporationArticles ? 'felix-select-outline' : undefined">
                <div class="flex align-items-center">
                  <div>
                    <p-checkbox [(ngModel)]="includeIncorporationArticles" [ngModelOptions]="{standalone: true}" inputId="incorporationArticles" [binary]="true"></p-checkbox>
                  </div>
                  <label for="incorporationArticles" class="flex align-items-center ml-2 cursor-pointer">
                    <span class="pi pi-receipt mr-2"></span>
                    <p class="text-sm">Articles of Incorporation</p>
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="einVerificationDocument" class="flex flex-column gap-2 w-5">
              <div class="bg-gray-50 p-2 border-round" [ngClass]="includeEinVerification ? 'felix-select-outline' : undefined">
                <div class="flex align-items-center">
                  <div>
                    <p-checkbox [(ngModel)]="includeEinVerification" [ngModelOptions]="{standalone: true}"  inputId="einVerification" [binary]="true"></p-checkbox>
                  </div>
                  <label for="einVerification" class="flex align-items-center ml-2 cursor-pointer">
                    <span class="pi pi-building-columns mr-2"></span>
                    <p class="text-sm">EIN Verification</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <app-documents-table id="docsTable" #docsTable [showControls]="false" [editMode]="true" [entity]="_entity"></app-documents-table>
        </div>
        <div class="mb-2">
          <p-divider></p-divider>
          <p-button [disabled]="sendDocumentsForm.invalid" (onClick)="submit()" [loading]="loading"  label="Send Documents"></p-button>
        </div>
      </div>
    </div>
  </form>
</p-scrollPanel>
