import {Document, DocumentSendLog, Folder} from "./document.model";
import {SecureLink} from "./securelink.model";
import {Activity, Note} from "./activity.model";

export enum EntityHomeDefaultView {
  "map" = "map",
  "list" = "list"
}
export interface Entity {
  entityId: number;
  name: string;
  ein?: string;
  activities: Activity[];
  notes?: Note[];
  incorporationDate?: Date | string;
  incorporationState?: string;
  description?: string;
  address?: Address;
  entityType: EntityType;
  //used temporarily for entity map
  id?: any;
  parentId: number | null;
  folders?: Folder[]
  documents?: Document[]
  magicLinks?: SecureLink[];
  documentSendLogs? : DocumentSendLog[];
  registeredAgentAddress?: Address;
  registeredAgentAddressId?: number;
  attributes?: EntityAttribute[];
  dbaNames?: string[];
}

export interface Address {
  street: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface EntityAttribute {
  entityAttributeId: number;
  createdAt: Date;
  updatedAt: Date;
  entityId: number;
  entityAttributeKeyId?: number;
  key: string;
  value: string;
}

export interface EntityAttributeKey {
  entityAttributeKeyId: number;
  createdAt: Date;
  updatedAt: Date;
  key: string;
  accountId?: string;
}

export enum EntityType {
  CORPORATION = 'CORPORATION',
  PARTNERSHIP = 'PARTNERSHIP',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  LIMITED_LIABILITY_COMPANY = 'LIMITED_LIABILITY_COMPANY',
  S_CORPORATION = 'S_CORPORATION'
}

export interface EntityCreateRequest {
  name: string;
  description?: string;
  address?: Address;
  entityType: EntityType;
  incorporationDate: Date;
}

export interface EntityUpdateRequest {
  name?: string;
  description?: string;
  address?: Address;
  entityType?: EntityType;
}
