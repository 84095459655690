
<!--<p-dialog-->
<!--  header="Join Today"-->
<!--  [modal]="true"-->
<!--  [(visible)]="showModal"-->
<!--  [closable]="false"-->
<!--  [style]="{ width: '90rem', height: '80rem' }"-->
<!--  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">-->
<!--  <stripe-pricing-table #pricingTable pricing-table-id="prctbl_1Q0Vek2MfSzfwBF6QM9L9JTM"-->
<!--                        customer-session-client-secret=""-->
<!--                        publishable-key="pk_test_51Q0CC82MfSzfwBF6Gnfy4Nnjg2UUuCRxRFmC6R8QpmUW9U6al0igIiNNCDNqdlyYEn97tWx5jZjlxa6tu2uiWC6U00KAikNdo2">-->
<!--  </stripe-pricing-table>-->
<!--</p-dialog>-->
<div class="p-4">
  <div *ngIf="subscription">
    <div *ngIf="!environment.production">
      <stripe-pricing-table #pricingTable pricing-table-id="prctbl_1Q0Vek2MfSzfwBF6QM9L9JTM"
                            customer-session-client-secret=""
                            publishable-key="pk_test_51Q0CC82MfSzfwBF6Gnfy4Nnjg2UUuCRxRFmC6R8QpmUW9U6al0igIiNNCDNqdlyYEn97tWx5jZjlxa6tu2uiWC6U00KAikNdo2">
      </stripe-pricing-table>
    </div>
    <div *ngIf="environment.production">
      <stripe-pricing-table pricing-table-id="prctbl_1Q8q9a2MfSzfwBF6TW5E51z3"
                            publishable-key="pk_live_51Q0CC82MfSzfwBF6BwvfvYHGaDqaNX9TIuiJdVIrLzAgGVoln1ZpBPqEudbbYbqSFPf32zYcmwjINr777x3MO6kn00dI7pn9Jf">
      </stripe-pricing-table>
    </div>
    <div class="border-1 border-round border-gray-200 m-auto px-4 py-2 mt-6 flex align-items-center justify-content-between" style="max-width: 45vw">
      <div>
        <p class="text-lg mb-1 font-bold">Felix Free</p>
        <p class="text-sm text-color-secondary">Continue with the felix free plan and limited features. No credit card required.</p>
      </div>
      <p-button [loading]="loading" label="Select Free Plan" (onClick)="selectFreePlan()" styleClass="text-sm"></p-button>
    </div>
  </div>
</div>
