import { PipeTransform, Pipe } from '@angular/core';
import {
  AUDIOMIMETYPES,
  IMAGEMIMETYPES, PDF_MIMETYPE, POWERPOINTMIMETYPES,
  SPREADSHEETMIMETYPES,
  VIDEOMIMETYPES,
  WORDMIMETYPES
} from "../constants/mimeTypes";

@Pipe({ name: 'mimeRename', standalone: true })
export class MimeTypeRenamePipe implements PipeTransform {
  transform(typeString: string): string {
    if (PDF_MIMETYPE === typeString) {
      return 'PDF';
    } else if (IMAGEMIMETYPES.includes(typeString)) {
      return 'Photo';
    } else if (VIDEOMIMETYPES.includes(typeString)) {
      return 'Video';
    } else if (SPREADSHEETMIMETYPES.includes(typeString)) {
      return 'Excel';
    } else if (WORDMIMETYPES.includes(typeString)) {
      return 'Doc';
    } else if (AUDIOMIMETYPES.includes(typeString)) {
      return 'Audio';
    } else if (POWERPOINTMIMETYPES.includes(typeString)) {
      return 'Presentation';
    } else {
      return 'Doc'
    }
  }
}
