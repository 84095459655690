import {Observable, of, throwError} from "rxjs";

export function handleError<T>(operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {
    console.error(error); // log to console instead
    return throwError(() => error);
  };
}

export function getRemainingDays(date: any) {
  var today = new Date();
  var date_to_reply = new Date(date);
  var timeinmilisec = date_to_reply.getTime() - today.getTime();
  return Math.max(Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24)), 0);
}
