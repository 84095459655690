import { Component } from '@angular/core';
import {DialogModule} from "primeng/dialog";

@Component({
  selector: 'app-loader',
  standalone: true,
    imports: [
        DialogModule
    ],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.css'
})
export class LoaderComponent {
  showLoader = true;
}
