// auth.guard.ts
import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map, tap } from 'rxjs/operators';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isLoggedIn().pipe(
    tap(loggedIn => {
      if (!loggedIn) {
        router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      }
    }),
    map(loggedIn => loggedIn)
  );
};

export const notLoggedIn: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isLoggedIn().pipe(
    tap(loggedIn => {
      debugger;
      if (loggedIn) {
        router.navigate(['/home']);
      }
    }),
    map(loggedIn => true)
  );
};
