import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {SecureLinksTableComponent} from "../shared/secure-links-table/secure-links-table.component";
import {EntityService} from "../../services/entity.service";
import {SecureLink} from "../../models/securelink.model";
import {Entity} from "../../models/entity.model";
import {TabViewModule} from "primeng/tabview";
import {TableModule} from "primeng/table";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {TagModule} from "primeng/tag";
import {Subject, takeUntil} from "rxjs";
import {SkeletonModule} from "primeng/skeleton";
import {BadgeModule} from "primeng/badge";
import {ChipModule} from "primeng/chip";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {UnsubscriberService} from "../../services/unsubscriber.service";

@Component({
  selector: 'app-document-logs-home',
  standalone: true,
  providers: [UnsubscriberService],
  imports: [
    TableModule,
    DatePipe,
    TagModule,
    NgForOf,
    SkeletonModule,
    BadgeModule,
    ChipModule,
    NgIf,
    OverlayPanelModule,
    ScrollPanelModule
  ],
  templateUrl: './document-logs-home.component.html',
  styleUrl: './document-logs-home.component.css'
})
export class DocumentLogsHomeComponent implements OnInit {
  private unsubService = inject(UnsubscriberService);
  documentSendLogs: any = [];
  loading = false;

  constructor(private entityService: EntityService) {}

  ngOnInit() {
    this.entityService.entities.pipe(this.unsubService.takeUntilDestroy).subscribe((entities: Entity[]) => {
      this.loading = false;
      if (!this.entityService.entitiesLoaded) {
        this.loading = true;
        this.entityService.getAllEntities();
      }
      if (entities && entities.length) {
        let logs: any = [];
        entities.forEach(entity => {
          if (entity.documentSendLogs?.length) {
            logs = [...logs, ...entity.documentSendLogs!]
          }
        });
        this.documentSendLogs = logs.sort((a,b) => new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime());
      }
    })
  }

}
