<h2>Billing</h2>

<div *ngIf="subscription">
  <p *ngIf="subscription?.status !== 'TRIALING'">Current Plan: <span class="font-bold">{{subscription?.product!.name}}</span></p>
  <p *ngIf="subscription?.status === 'TRIALING'">Current Plan: <span class="font-bold">Felix Pro Trial</span></p>
  <p *ngIf="subscription?.payments?.length">Last Payment: <span class="text-color-secondary">{{subscription!.payments[0].createdAt | date: 'M/dd/yyyy'}}</span></p>
  <p *ngIf="subscription?.status !== 'TRIALING'">Renews On: <span class="text-color-secondary">{{subscription?.currentPeriodEnd | date: 'M/dd/yyyy'}}</span></p>
  <p *ngIf="subscription?.status === 'TRIALING'">Ends On: <span class="text-color-secondary">{{subscription?.currentPeriodEnd | date: 'M/dd/yyyy'}}</span></p>

  <div *ngIf="subscription.status === 'PAST_DUE'" class="border-1 border-round border-gray-200 mb-3 px-4 py-2 mt-3 flex align-items-center justify-content-between" style="max-width: 25vw">
    <div>
      <p class="text-lg mb-1 font-bold flex align-items-center"><span class="pi pi-exclamation-circle text-yellow-500 mr-2 text-2xl"></span>Subscription Past Due</p>
      <p class="text-sm text-color-secondary">It looks like your subscription payment is past due. Please check your payment method and make a payment to resume service.</p>
    </div>
  </div>

  <p-button *ngIf="subscription?.status === 'TRIALING'" [loading]="loading" [routerLink]="'../plan-selection'" label="Upgrade"></p-button>
  <p-button *ngIf="subscription?.status !== 'TRIALING'" [loading]="loading" (onClick)="goToBillingPortal()" label="Manage Subscription"></p-button>
</div>
