// auth.service.ts
import {inject, Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {Auth, authState, sendEmailVerification, signInWithEmailAndPassword, signOut, User} from '@angular/fire/auth'; // Import Firebase Auth
import {BehaviorSubject, Observable, Subject, takeUntil} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Entity} from "../models/entity.model";
import {HttpClient} from "@angular/common/http";
import {handleError} from "../utils/helpers";
import {environment} from "../../environments/environment";
import {FelixUser, UserCreateRequest, UserProfileUpdateRequest} from "../models/user.model";
import {DocumentUploadRequest} from "../models/document.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private auth = inject(Auth);
  user$ = authState(this.auth);
  apiPrefix = "users";
  private apiUrl = `${environment.apiUrl}/${this.apiPrefix}`;
  userDetails: BehaviorSubject<FelixUser | undefined> = new BehaviorSubject<FelixUser|undefined>(undefined);
  private readonly _destroy$ = new Subject<void>();

  constructor(private router: Router, private http: HttpClient) {
    this.user$.pipe(takeUntil(this._destroy$)).subscribe((res) => {
      if (res) {
        this.getUserDetails().subscribe();
      }
    })
  }

  isLoggedIn(): Observable<boolean> {
    return this.user$.pipe(
      map(user => !!user)
    );
  }

  login(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password)
  }

  logout() {
    return signOut(this.auth).then(() => {
      this.userDetails.next(undefined);
      this.router.navigate(['/login']);
    });
  }

  //gets user details from database
  getUserDetails() : Observable<FelixUser> {
    return this.http.get<{data: FelixUser}>(this.apiUrl + "/profile")
      .pipe(
        map(response => response.data),
        catchError(handleError<FelixUser>('getUserDetails')),
        tap(user => this.userDetails.next(user))
      )
  }

  refreshFirebaseUser() {
    this.auth.currentUser?.reload();
  }

  //registers a new user and creates an account
  register(data: UserCreateRequest) : Observable<FelixUser> {
    return this.http.post<{data: FelixUser}>(this.apiUrl, data)
      .pipe(
        map(response => response.data),
        catchError(handleError<FelixUser>('registerUser'))
      );
  }

  sendVerificationEmail() {
    return sendEmailVerification(this.auth.currentUser!);
  }

  updateProfile(updates: UserProfileUpdateRequest) {
    return this.http.put<{data: FelixUser}>(`${this.apiUrl}/profile`, updates)
      .pipe(
        map(response => response.data),
        catchError(handleError<FelixUser>('updateProfile'))
      );
  }

  uploadProfileImage(image: any): Observable<any> {
    const formData = new FormData();
    formData.append('image', image, image.name)
    return this.http.put<{data: any}>(`${this.apiUrl}/profile/image`, formData, {
      reportProgress: true
    }) // trigger header for the intercepter
      .pipe(
        map(response => response.data),
        catchError(handleError<Document[]>('uploadProfileImage'))
      );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
