import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, MessageService, TreeNode} from "primeng/api";
import {TreeTable, TreeTableModule} from "primeng/treetable";
import {Button} from "primeng/button";
import {CommonModule, NgForOf, NgIf} from "@angular/common";
import {DialogModule} from "primeng/dialog";
import {DocumentsUploadComponent} from "../documents-upload/documents-upload.component";
import {TreeSelectModule} from "primeng/treeselect";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DocumentService} from "../../../services/document.service";
import {Entity} from "../../../models/entity.model";
import {
  Document,
  DocumentType,
  DocumentUpdateRequest,
  Folder,
  FolderUpdateRequest
} from "../../../models/document.model";
import {ConfirmDialog, ConfirmDialogModule} from "primeng/confirmdialog";
import {firstValueFrom, lastValueFrom} from "rxjs";
import {EntityService} from "../../../services/entity.service";
import {CheckboxModule} from "primeng/checkbox";
import {ContextMenuModule} from "primeng/contextmenu";
import {Table, TableModule, TableService} from "primeng/table";
import {MenuModule} from "primeng/menu";
import {MimeTypeRenamePipe} from "../../../pipes/mimeTypeRename.pipe";
import {FileSizePipe} from "../../../pipes/fileSize.pipe";
import {MimeTypeIconPipe} from "../../../pipes/mimeTypeIcon.pipe";
import {ChipModule} from "primeng/chip";
import {DocumentChipPipe} from "../../../pipes/documentChip.pipe";
import {TagModule} from "primeng/tag";
import {DragDropModule} from "primeng/dragdrop";
import {TruncatePipe} from "../../../pipes/truncate.pipe";

@Component({
  selector: 'app-documents-table',
  standalone: true,
  imports: [
    TreeTableModule,
    Button,
    CommonModule,
    NgForOf,
    NgIf,
    DialogModule,
    DocumentsUploadComponent,
    TreeSelectModule,
    InputTextModule,
    FormsModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    CheckboxModule,
    ContextMenuModule,
    TableModule,
    MenuModule,
    MimeTypeRenamePipe,
    FileSizePipe,
    MimeTypeIconPipe,
    ChipModule,
    DocumentChipPipe,
    TagModule,
    DragDropModule,
    TruncatePipe
  ],
  providers: [TableService, Table],
  templateUrl: './documents-table.component.html',
  styleUrl: './documents-table.component.css'
})
export class DocumentsTableComponent implements OnInit, AfterViewInit {
  entity: Entity | undefined;
  @ViewChild('docsTable') docsTable: TreeTable | undefined;
  @Input() showControls: boolean = true;
  @Input('entity') set _entity(value: Entity | undefined) {
    this.entity = value;
    this.availableFolders = [];
    //this.tableLoading = false;
    if (this.entity?.folders?.length) {
      this.availableFolders = this.entity.folders?.filter((folder) => !folder.parentFolderId).map((folder) => {
        return {
          label: folder.name,
          children: this.getChildren(folder, true),
          data: {
            folderId: folder.id,
            type: "folder"
          }
        }
      });
    }
    this.buildDocuments();
  }
  @Input() editMode = false;
  cols!: any[];
  showNewFolderDialog;
  showMoveFolderDialog;
  showRenameDialog;
  availableFolders: TreeNode[] = [];
  parentFolder: TreeNode | undefined;
  moveToFolder: TreeNode | undefined;
  documents: TreeNode[] = [];
  selectedTreeNode!: TreeNode;
  selectedTreeNodes: TreeNode[] = [];
  loadingCreateFolder = false;
  updatingDocument = false;
  newDocumentFolderName = "";
  rowBeingDragged;
  highlightDrop;
  protected DocumentType = DocumentType;
  selectionKeys = {
    1: {
      partialChecked: true,
      checked: false
    }
  };
  showUploadDialog = false;
  folderName = "";
  tableLoading = true;
  documentRowOptionsMenu = [
    { label: 'Rename', icon: 'pi pi-file-edit', command: () => {this.test()}},
    { label: 'Move', icon: 'pi pi-folder-open', command: () => {this.showMoveFolderDialog = true} },
    { label: 'Delete', icon: 'pi pi-trash', command: () => {this.confirmDeleteDocument()} },
    { label: 'Download', icon: 'pi pi-download', command: (event) => {this.nodeSelect(event)} },
  ];
  constructor(private documentService: DocumentService, private messageService: MessageService, private confirmationService: ConfirmationService, private entityService: EntityService) {
    this.cols = [
      { field: 'name', header: 'Name', width: "50%" },
      { field: 'size', header: 'Size', width: "10%" },
      { field: 'fileType', header: 'Type', width: "10%" },
      { field: 'updated', header: 'Last Updated', width: "20%" },
      { field: 'menu', header: '', width: "10%" }
    ];
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // if (this.entity?.folders?.length) {
    //   this.availableFolders = this.entity.folders.map((folder) => {
    //     return {
    //       label: folder.name,
    //     }
    //   });
    // }
  }

  createFolder() {
    this.loadingCreateFolder = true;
    this.documentService.createFolder({
      name: this.folderName,
      parentFolderId: this.parentFolder?.data.folderId,
      entityId: this.entity?.entityId!
    }).subscribe((res) => {
      this.loadingCreateFolder = false;
      this.entityService.getEntity(this.entity!.entityId);
      this.selectedTreeNodes = [];
      this.folderName = "";
      this.showNewFolderDialog = false;
    })
  }

  test(): any {
    const t = this.selectedTreeNodes;
    this.showRenameDialog = true;
  }

  buildDocuments() {
    if (this.entity) {
      //get all top level folders and documents;
      const topLevelFolders = [...this.entity.folders!.filter((folder) => !folder.parentFolderId)];
      const topLevelDocuments = [...this.entity.documents!.filter((doc) => !doc.folderId)];

      this.documents = topLevelFolders!.map((folder) => {
        return {
          label: folder.name,
          key: folder.id,
          children: this.getChildren(folder),
          icon: "pi pi-folder",
          draggable: true,
          droppable: true,
          data: {
            "name": folder.name,
            "uploaded": folder.createdAt,
            "updated": folder.updatedAt,
            "size": "",
            "folderId": folder.id,
            "type": "folder",
            "fileType": "",
            key: folder.id
          }
        }
      });
      this.documents = [...this.documents, ...topLevelDocuments!.map((document) => {
        return {
          label: document.name,
          key: document.documentId.toString(),
          icon: new MimeTypeIconPipe().transform(document.mimeType),
          data: {
            "name": document.name,
            "documentId": document.documentId,
            "type": "document",
            "uploaded": document.createdAt,
            "updated": document.updatedAt,
            "size": document.size,
            "fileType": document.mimeType,
            "documentType": document.documentType,
            key: document.documentId.toString()
          }
        }
      }).filter(Boolean)];
    }
  }

  nodeSelect($event?) {
    this.messageService.add({ severity: 'info', icon: 'pi pi-spin pi-spinner', key: 'global', summary: 'Downloading Document', detail: 'Preparing your download', life: 3000 });

    if (this.selectedTreeNode.data.type === "document") {
      this.documentService.downloadDocument(this.entity!.entityId, this.selectedTreeNode.data.documentId).subscribe((res: any) => {
        let dataType = res.type;
        let binaryData: any = [];
        binaryData.push(res);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', this.selectedTreeNode.data.name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      })
    } else if (this.selectedTreeNode.data.type === "folder") {
      this.documentService.downloadFolder(this.entity!.entityId, this.selectedTreeNode.data.folderId).subscribe((res: any) => {
        let dataType = res.type;
        let binaryData: any = [];
        binaryData.push(res);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', this.selectedTreeNode.data.name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      })
    }
  }

  refreshEntity() {
    this.entityService.getEntity(this.entity!.entityId);
  }

  confirmDeleteDocument() {
    let deleteType;
    debugger;
    if (this.isBulkSelection()) {
      deleteType = this.selectedTreeNodes[0].data.type === 'folder' ? 'folder' : 'document';
    } else {
      deleteType = this.selectedTreeNode.data.type === 'folder' ? 'folder' : 'document';
    }
    this.confirmationService.confirm({
      key: 'deleteConfirm',
      message: `Are you sure you want to delete this ${deleteType}?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass:"p-button-danger",
      rejectLabel: "Cancel",
      rejectButtonStyleClass:"",
      acceptIcon:"none",
      rejectIcon:"none",
      accept: () => {this.deleteDocuments();},
      reject: () => {

      }
    });
  }

  async handleDocumentRename(item: TreeNode, newName: string) {
    this.updatingDocument = true;
    if (item.data.type === "document") {
      await this.updateDocument(item.data.documentId, {name: newName});
    } else if (item.data.type === "folder") {
      await this.updateFolder(item.data.folderId, {name: newName});
    }
    this.showRenameDialog = false;
    this.updatingDocument = false;
    this.selectedTreeNodes = [];
    this.newDocumentFolderName = "";
    this.entityService.getEntity(this.entity!.entityId);
  }

  async handleDocumentMove() {
    let selectedNodes = this.selectedTreeNodes;
    if (!this.isBulkSelection()) {
      selectedNodes = [this.selectedTreeNode];
    }
    Promise.all(selectedNodes.map(async (doc) => {
      if (doc.data.type === "document") {
        await this.updateDocument(doc.data.documentId, {folderId: Number(this.moveToFolder!.data.folderId)});
      } else if (doc.data.type === "folder") {
        await this.updateFolder(doc.data.folderId, {parentFolderId: Number(this.moveToFolder!.data.folderId)});
      }
    })).then((res) => {
      this.showMoveFolderDialog = false;
      this.selectedTreeNodes = [];
      this.entityService.getEntity(this.entity!.entityId);
    })
  }

  async updateDocument(documentId: number, updates: DocumentUpdateRequest) {
    await this.documentService.updateDocument(this.entity!.entityId, documentId, updates).subscribe();
  }

  async updateFolder(folderId: number, updates: FolderUpdateRequest) {
    await this.documentService.updateFolder(this.entity!.entityId, folderId, updates).subscribe();
  }

  async deleteDocuments() {
    let selectedNodes = this.selectedTreeNodes;
    if (!this.isBulkSelection()) {
      selectedNodes = [this.selectedTreeNode];
    }
    Promise.all(selectedNodes.map(async (doc) => {
      if (doc.data.type === "document") {
        await firstValueFrom(this.documentService.deleteDocument(this.entity?.entityId, doc.data.documentId));
      } else if (doc.data.type === "folder") {
        await firstValueFrom(this.documentService.deleteFolder(this.entity?.entityId, doc.data.folderId));
      }
    })).then((res) => {
      this.selectedTreeNodes = [];
      this.entityService.getEntity(this.entity!.entityId);
    })
  }

  getChildren(folder: Folder, onlyFolders = false) {
    let documents = folder.documents?.map((document) => {
      return {
        label: document.name,
        key: document.documentId.toString(),
        icon: new MimeTypeIconPipe().transform(document.mimeType),
        data: {
          "name": document.name,
          "documentId": document.documentId,
          "type": "document",
          "uploaded": document.createdAt,
          "updated": document.updatedAt,
          "size": document.size,
          "fileType": document.mimeType,
          "documentType": document.documentType,
          key: document.documentId.toString()
        }
      }
    }).filter(Boolean);
    let subfolders = folder.subFolders?.map((subfolder) => {
      return {
        label: subfolder.name,
        key: subfolder.id,
        children: this.getChildren(subfolder),
        icon: "pi pi-folder",
        data: {
          "name": subfolder.name,
          "uploaded": subfolder.createdAt,
          "updated": subfolder.updatedAt,
          "size": "",
          "folderId": subfolder.id,
          "type": "folder",
          "fileType": "",
          key: folder.id + "-" + subfolder.id
        }
      }
    }).filter(Boolean);
    if (!documents || onlyFolders) {
      documents = [];
    }
    if (!subfolders) {
      subfolders = [];
    }
    return [...documents as TreeNode[], ...subfolders as TreeNode[]];
  }

  isRowSelected(rowNode: any): boolean {
    return this.selectedTreeNodes.indexOf(rowNode.node) >= 0;
  }

  toggleRowSelection(rowNode: any): void {
    if (this.isRowSelected(rowNode)) {
      this.selectedTreeNodes.splice(this.selectedTreeNodes.indexOf(rowNode.node), 1);
    } else {
      this.selectedTreeNodes.push(rowNode.node);
    }
    this.selectedTreeNodes = [...this.selectedTreeNodes];
  }

  isBulkSelection() {
    return !this.selectedTreeNode;
  }

  filteredDocuments() {
    return this.documents.filter(d => d.data.documentType === DocumentType.GENERAL || d.data.type === 'folder');
  }

  handleOnDrag(event, row) {
    this.rowBeingDragged = row.node;
  }

  async handleOnDrop(event, dropRow) {
    this.highlightDrop = undefined;
    let folderId: any = null;
    // otherwise folderid will be null and this was dragging a doc out of a folder to the root
    if (dropRow && dropRow.node.data.type === "folder") {
      folderId = Number(dropRow.node.data.folderId)
    }
    //did they drag a document
    if (this.rowBeingDragged.data.type === "document") {
      const matchingDoc = this.entity!.documents!.find((document) => document.documentId === this.rowBeingDragged.data.documentId);
      //update the folder children for quick feedback
      if (matchingDoc && matchingDoc.folderId) {
        //remove it from the previous folder
        let docFolder = this.entity!.folders!.find((folder) => Number(folder.id) === matchingDoc.folderId);
        docFolder!.documents = docFolder?.documents?.filter((document) => document.documentId !== matchingDoc.documentId);
      }
      if (folderId) {
        //add it to the new folder
        let docFolder = this.entity!.folders!.find((folder) => Number(folder.id) === folderId);
        docFolder!.documents!.push(matchingDoc!);
      }
      matchingDoc!.folderId = folderId;
      this.buildDocuments();
      //actually update it via the api
      await this.updateDocument(this.rowBeingDragged.data.documentId, {folderId});
    } else if (this.rowBeingDragged.data.type === "folder") {
      //they dragged a folder
      const matchingFolder = this.entity!.folders!.find((folder) => folder.id === this.rowBeingDragged.data.folderId);
      //update the folder children for quick feedback
      if (matchingFolder && matchingFolder.parentFolderId) {
        //remove it from the previous folder
        let parentFolder = this.entity!.folders!.find((folder) => Number(folder.id) === matchingFolder.parentFolderId);
        parentFolder!.subFolders = parentFolder?.subFolders?.filter((folder) => folder.id !== matchingFolder.id);
      }
      if (folderId) {
        //add it to the new folder
        let newParentFolder = this.entity!.folders!.find((folder) => Number(folder.id) === folderId);
        newParentFolder!.subFolders!.push(matchingFolder!);
      }
      matchingFolder!.parentFolderId = folderId;
      this.buildDocuments();
      this.docsTable!.reset();
      //actually update it via the api
      await this.updateFolder(this.rowBeingDragged.data.folderId, {parentFolderId: folderId});
    }
  }

  handleOnDragEnter($event, key) {
    this.highlightDrop = key;
  }
}
