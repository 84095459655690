<div class="p-4">
<p-breadcrumb
  class="max-w-full"
  [model]="breadcrumItems"
  [home]="home" />

  <div class="entity-quick-panel grid nested-grid">
    <div class="col-8">
      <div class="grid">
        <div class="col-4">
          <div class="border-round-sm p-3 bg-gray-100 ">
            <div class="flex align-items-center justify-content-between">
              <label class="text-sm">Legal Name</label>
              <p-button icon="pi pi-copy" [rounded]="true" [text]="true" [cdkCopyToClipboard]="getEntityInfo('name')" />
            </div>
            <h3 class="text-black-alpha-80">{{entity?.name}}</h3>
          </div>
        </div>
        <div class="col-4">
          <div class="border-round-sm p-3 bg-gray-100">
            <div class="flex align-items-center justify-content-between">
              <label class="text-sm">EIN</label>
              <p-button icon="pi pi-copy" [rounded]="true" [cdkCopyToClipboard]="getEntityInfo('ein')" [text]="true" />
            </div>
            <h3 class="text-black-alpha-80">{{entity?.ein}}</h3>
          </div>
        </div>
        <div class="col-4">
          <div class="border-round-sm p-3 bg-gray-100">
            <div class="flex align-items-center justify-content-between">
              <label class="text-sm">Incorporation Date</label>
              <p-button icon="pi pi-copy" [rounded]="true" [cdkCopyToClipboard]="getEntityInfo('incorporationDate')" [text]="true" />
            </div>
            <h3 class="text-black-alpha-80">{{entity?.incorporationDate | date : 'MM/dd/yyyy'}}</h3>
          </div>
        </div>
        <div class="col-6">
          <div class="text-black-alpha-80 p-3 bg-gray-100 border-round-sm">
            <label class="text-sm">Address</label>
            <h3>{{entity?.address?.street}}</h3>
            <h3>{{entity?.address?.city}}, {{entity?.address?.state}} {{entity?.address?.postalCode}}</h3>
          </div>
        </div>
        <div class="col-6">
          <div class="text-black-alpha-80 p-3 bg-gray-100 border-round-sm">
            <label class="text-sm">Registered Agent</label>
            <h3>{{entity?.registeredAgentAddress?.street}}</h3>
            <h3>{{entity?.registeredAgentAddress?.city}}, {{entity?.registeredAgentAddress?.state}} {{entity?.registeredAgentAddress?.postalCode}}</h3>
          </div>
        </div>
      </div>
      <p-divider></p-divider>
      <div>
        <app-documents-table [entity]="entity"></app-documents-table>
      </div>
    </div>
    <div class="col">
      <div class="border-round-sm px-3 py-2 flex flex-column">
        <p-button icon="pi pi-link" (onClick)="showMagicLinks = true" [text]="true" label="Secure Links" styleClass="mb-1" severity="success" />
        <p-divider styleClass="my-1"></p-divider>
        <p-button icon="pi pi-send" [text]="true" (onClick)="showSendDocumentsForm = true"  label="Send Documents" styleClass="mb-1" severity="success" />
        <p-divider styleClass="my-1"></p-divider>
        <p-button icon="pi pi-pencil" [text]="true" (onClick)="showEditEntity = true" label="Edit Entity" styleClass="mb-1" severity="success" />
        <p-divider styleClass="my-1"></p-divider>
      </div>
      <div class="px-3">
        <p class="text-xl font-bold">Activity</p>
          <app-activity-feed *ngIf="entity && entity.activities" [activities]="entity!.activities!" (onOpenNote)="openNote($event)" [notes]="entity!.notes!"></app-activity-feed>
        <div class="mb-2">
          <p-divider></p-divider>
          <p-button [text]="true" pTooltip="Use this to record a short description of an event or activity." (onClick)="showEditor = true" [outlined]="true" label="New Activity" class="mr-3"></p-button>
          <p-button [text]="true" pTooltip="Use this to create long form content of something noteworthy." (onClick)="showNoteEditor = true" [outlined]="true" label="New Note"></p-button>
        </div>
        <div *ngIf="showEditor">
          <p-editor [(ngModel)]="newActivityText" [style]="{ height: '100px' }">
            <ng-template pTemplate="header">
              <div class="flex justify-content-between align-items-center">
                <span class="ql-formats mr-5">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline" aria-label="Underline"></button>
                </span>
                <p-calendar
                  [iconDisplay]="'input'"
                  [showIcon]="true"
                  [(ngModel)]="newActivityDate"
                  [showButtonBar]="true"
                  appendTo="body"
                  styleClass="w-10rem"
                  inputId="birth_date" />
              </div>
            </ng-template>
          </p-editor>
          <p-button (onClick)="createActivity()" [loading]="loading" label="Save" styleClass="mt-2"></p-button>
        </div>
      </div>
    </div>
  </div>

</div>

<p-dialog header="Secure Links" [modal]="true" [(visible)]="showMagicLinks" [style]="{'max-width': '75%'}">
  <div class="mb-5">Secure Links can be used to temporarily and securely share information about your entity with a 3rd party. Those with the link will be
  able to access the information and documents that you've made available when creating the link.</div>
  <div>
    <p-tabView>
      <p-tabPanel header="Active Links">
        <div class="flex align-items-center gap-3 mb-5">
          <app-secure-links-table [secureLinks]="entity?.magicLinks!" [displayFilteredStatus]="'active'"></app-secure-links-table>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Expired Links">
        <div class="flex align-items-center gap-3 mb-5">
          <app-secure-links-table [secureLinks]="entity?.magicLinks!" [displayFilteredStatus]="'expired'"></app-secure-links-table>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button label="Create New Link" [outlined]="true" (onClick)="showMagicLinks = false; showNewMagicLinkForm = true;" />
  </div>
</p-dialog>

<p-sidebar [blockScroll]="true" [(visible)]="showNewMagicLinkForm" position="right" [style]="{'min-width': '33vw'}">
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <i class="pi pi-link" style="font-size: 1.5rem"></i>
      <!--      <p-avatar-->
      <!--        image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"-->
      <!--        shape="circle" />-->
      <h2 class="font-bold">
        New Secure Link
      </h2>
    </div>
  </ng-template>
  <app-create-secure-link *ngIf="showNewMagicLinkForm" [entity]="entity" (onClose)="this.showNewMagicLinkForm = false; this.showMagicLinks = true"></app-create-secure-link>
</p-sidebar>

<p-sidebar [blockScroll]="true" [(visible)]="showSendDocumentsForm" position="right" [style]="{'min-width': '33vw'}">
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <h2 class="font-bold">
        Send Documents
      </h2>
    </div>
  </ng-template>
  <app-send-documents *ngIf="showSendDocumentsForm" [entity]="entity" (onClose)="this.showSendDocumentsForm = false;"></app-send-documents>
</p-sidebar>

<p-dialog [header]="selectedNote ? selectedNote.title : 'New Note'" [modal]="true" [(visible)]="showNoteEditor" [style]="{'width': '65%', 'height': '90vh'}">
<!--  <ng-template pTemplate="header">-->
<!--    <div class="flex align-items-center">-->
<!--      <p>Note Title</p>-->
<!--      <input type="text" placeholder="Note Title" class="mb-2 w-25rem" pInputText />-->
<!--    </div>-->
<!--  </ng-template>-->
  <app-notes-editor (onClose)="showNoteEditor = false; selectedNote = undefined" [entityId]="entity?.entityId" [note]="selectedNote!" (onSave)="noteSaved()"></app-notes-editor>
</p-dialog>

<p-sidebar [blockScroll]="true" [(visible)]="showEditEntity" position="right" [style]="{'min-width': '33vw'}">
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <i class="pi pi-building" style="font-size: 1.5rem"></i>
      <!--      <p-avatar-->
      <!--        image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"-->
      <!--        shape="circle" />-->
      <h2 class="font-bold">
        Edit Entity
      </h2>
    </div>
  </ng-template>
  <app-add-edit-entity *ngIf="showEditEntity" [entity]="entity!" (complete)="entityUpdatedHandler($event)"></app-add-edit-entity>
</p-sidebar>
