<div id="bgnd" class="w-full h-full p-0 m-0">
  <div class="grid h-full p-0 m-0">
    <div class="col-8 h-full">
      <div class="flex flex-column align-items-center justify-content-center h-full">
        <h2 class="mb-0">Start Managing Your Entities the Smart Way</h2>
        <p class="mt-1">Sign up in seconds. No credit card required.</p>
        <p-card styleClass="max-w-30rem" [style]="{'min-width': '30vw'}">
          <form [formGroup]="registerForm" (ngSubmit)="register()">
            <div class="flex">
              <div class="flex flex-column gap-1 mb-3 mr-2 flex-grow-1">
                <label for="firstName" class="font-semibold">First Name</label>
                <input pAutoFocus pInputText id="firstName" formControlName="firstName" autocomplete="off" />
              </div>
              <div class="flex flex-column gap-1 mb-3 flex-grow-1">
                <label for="lastName" class="font-semibold">Last Name</label>
                <input pInputText id="lastName" formControlName="lastName" autocomplete="off" />
              </div>
            </div>
            <div class="flex flex-column gap-1 mb-3">
              <label for="email" class="font-semibold">Email Address</label>
              <input pInputText id="email" type="email" formControlName="email" autocomplete="off" />
            </div>
            <div class="flex flex-column gap-1 mb-5 flex-grow-1">
              <label for="password" class="font-semibold">Password</label>
              <p-password id="password" [inputStyle]="{'width':'100%'}" [style]="{'width':'100%'}" formControlName="password" [feedback]="true" [toggleMask]="true">
                <ng-template pTemplate="header">
                  <h6>Pick a password</h6>
                </ng-template>
                <ng-template pTemplate="footer">
                  <p-divider />
                  <p class="mt-2">Requirements</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric</li>
                    <li>Minimum 8 characters</li>
                  </ul>
                </ng-template>
              </p-password>
            </div>
            <div class="flex flex-column gap-1 mb-5">
              <label for="confirmPassword" class="font-semibold">Confirm Password</label>
              <p-password id="confirmPassword" [inputStyle]="{'width':'100%'}" [style]="{'width':'100%'}" formControlName="confirmPassword" [feedback]="false" [toggleMask]="true" />
            </div>
            <div>
              <p *ngIf="showError" class="p-error text-red500">{{errorMessage}}</p>
            </div>
            <div>
              <p-button styleClass="w-full" type="submit" [loading]="loading" [disabled]="registerForm.invalid" label="Create Account" />
            </div>
          </form>
          <div class="text-center mt-4">
            <span class="p-text-secondary">Already have an account? <span [routerLink]="'/login'" class="p-link underline">Login</span></span>
          </div>
        </p-card>
      </div>
    </div>
    <div class="col-4 p-0 m-0">
      <div class="w-full h-full bg-green-50 flex flex-column justify-content-center align-items-start pl-8">
<!--        <img style="max-width: 550px;" class="border-round opacity-30 h-16rem" src="entity_bgnd.png"/>-->
        <h2 class="mb-2">Try Felix Pro features for 30 days</h2>
        <p class="mt-1 mb-6">Your 30-day trial of features includes:</p>
        <div>
          <div *ngFor="let feature of features" class="flex align-items-center justify-content-start mb-4">
            <span class="pi pi-check-circle mr-2"></span>
            <span>{{feature}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
