import { PipeTransform, Pipe } from '@angular/core';
import {
  AUDIOMIMETYPES,
  IMAGEMIMETYPES, PDF_MIMETYPE,
  POWERPOINTMIMETYPES,
  SPREADSHEETMIMETYPES,
  VIDEOMIMETYPES,
  WORDMIMETYPES
} from "../constants/mimeTypes";

@Pipe({ name: 'mimeIcon', standalone: true })
export class MimeTypeIconPipe implements PipeTransform {
  transform(mimeType: string): string {
    if (PDF_MIMETYPE === mimeType) {
      return 'pi pi-file-pdf';
    } else if (IMAGEMIMETYPES.includes(mimeType)) {
      return 'pi pi-image';
    } else if (WORDMIMETYPES.includes(mimeType)) {
      return 'pi pi-file-word';
    } else if (SPREADSHEETMIMETYPES.includes(mimeType)) {
      return 'pi pi-file-excel';
    } else if (VIDEOMIMETYPES.includes(mimeType)) {
      return 'pi pi-video';
    } else if (POWERPOINTMIMETYPES.includes(mimeType)) {
      return 'pi pi-chart-line';
    } else if (AUDIOMIMETYPES.includes(mimeType)) {
      return 'pi pi-headphones';
    } else {
      return 'pi pi-file';
    }
  }
}
