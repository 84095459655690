import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {PrimeTemplate} from "primeng/api";
import {SafeHtmlPipe} from "../../../pipes/safeHtml.pipe";
import {TimelineModule} from "primeng/timeline";
import {CardModule} from "primeng/card";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {TabViewModule} from "primeng/tabview";
import {Activity, Note} from "../../../models/activity.model";
import {TruncatePipe} from "../../../pipes/truncate.pipe";
import {StripHtmlPipe} from "../../../pipes/stripHtml.pipe";

@Component({
  selector: 'app-activity-feed',
  standalone: true,
  imports: [
    DatePipe,
    PrimeTemplate,
    SafeHtmlPipe,
    TimelineModule,
    CardModule,
    NgForOf,
    ScrollPanelModule,
    TabViewModule,
    NgIf,
    TruncatePipe,
    StripHtmlPipe
  ],
  templateUrl: './activity-feed.component.html',
  styleUrl: './activity-feed.component.css'
})
export class ActivityFeedComponent {
  @Input() activities: Activity[] = [];
  @Input() notes: Note[] = [];
  @Output() onOpenNote: EventEmitter<Note> = new EventEmitter<Note>();

  openNote(note: Note) {
    this.onOpenNote.emit(note);
  }
}
