import {AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, inject, ViewChild} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {DialogModule} from "primeng/dialog";
import {AccountService} from "../../services/account.service";
import {BillingService} from "../../services/billing.service";
import {take} from "rxjs/operators";
import {Button} from "primeng/button";
import {Subscription, SubscriptionStatus} from "../../models/subscription.model";
import {NgIf} from "@angular/common";
import {Router} from "@angular/router";
import {UnsubscriberService} from "../../services/unsubscriber.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-plan-selection',
  standalone: true,
  imports: [
    DialogModule,
    Button,
    NgIf
  ],
  providers: [UnsubscriberService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './plan-selection.component.html',
  styleUrl: './plan-selection.component.css'
})
export class PlanSelectionComponent implements AfterViewInit {
  @ViewChild('pricingTable') pricingTable: ElementRef | undefined;
  private unsubService = inject(UnsubscriberService);
  showModal = true;
  loading = false;
  subscription: Subscription | undefined;
  constructor(private authService: AuthService, private accountService: AccountService, private billingService: BillingService, private router: Router) {
  }

  ngAfterViewInit() {
    this.authService.userDetails.pipe(this.unsubService.takeUntilDestroy).subscribe((user) => {
      if (user) {
        //this.pricingTable?.nativeElement.setAttribute('customer-email', user.email);
      }
    });
    this.accountService.activeAccount.pipe(this.unsubService.takeUntilDestroy).subscribe((account) => {
      if (account) {
        //this.pricingTable?.nativeElement.setAttribute('client-reference-id', account?.accountId);
        this.billingService.createCustomerSession().pipe(take(1)).subscribe((session) => {
          if (session) {
            this.pricingTable?.nativeElement.setAttribute('customer-session-client-secret', session.client_secret);
          }
        });
        this.subscription = account.subscriptions.find((s) => s.status !== SubscriptionStatus.CANCELED);
      }
    });
  }

  selectFreePlan() {
    this.loading = true;
    this.billingService.updateSubscription(this.subscription!.id, {productId: 1}).subscribe(async (res) => {
      this.loading = false;
      await this.router.navigate(["/home"]).then((res) => {})
        this.accountService.getAccount(this.subscription!.accountId)
    })
  }

  protected readonly environment = environment;
}
