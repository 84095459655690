import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {map, Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {SecureLink, SecureLinkCreateRequest} from "../models/securelink.model";
import {handleError} from "../utils/helpers";
import {Folder} from "../models/document.model";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class SecurelinkService {
  private apiPrefix = "secure-links"
  private apiUrl = `${environment.apiUrl}/${this.apiPrefix}`;

  constructor(private http: HttpClient) {}

  // Create a new secure link
  createSecureLink(linkData: SecureLinkCreateRequest): Observable<SecureLink> {
    return this.http.post<{data:  SecureLink}>(this.apiUrl, linkData)
      .pipe(
        map(response => response.data),
        catchError(handleError<SecureLink>('createSecureLink'))
      );
  }

  getSecureLinks(entityId: number): Observable<SecureLink[]> {
    return this.http.get<{data:  SecureLink[]}>(this.apiUrl)
      .pipe(
        map(response => response.data),
        catchError(handleError<SecureLink[]>('getSecureLinks'))
      );
  }

  getSecureLinkDetailsByToken(token: string): Observable<any> {
    return this.http.get<{data:  SecureLink}>(this.apiUrl+`/public/${token}`)
      .pipe(
        map(response => response.data),
        catchError((err): any => {
          if (err.status === 410) {
            return of("expired");
          } else {
            return handleError<SecureLink>('createSecureLink')
          }
        })
      );
  }

  downloadSecureLinkDocument(token: string, documentId: string) {
    return this.http.get<{data: String}>(`${this.apiUrl}/public/${token}/documents/${documentId}`, {responseType: 'blob' as 'json'})
      .pipe(
        catchError(handleError<SecureLink>('downloadDocument'))
      );
  }

  // Handle HTTP operation that failed.
  // private handleError<T>(operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {
  //     console.error(error); // log to console instead
  //     return of(result as T);
  //   };
  // }

}
