import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {SecureLinksTableComponent} from "../shared/secure-links-table/secure-links-table.component";
import {EntityService} from "../../services/entity.service";
import {SecureLink} from "../../models/securelink.model";
import {Entity} from "../../models/entity.model";
import {TabViewModule} from "primeng/tabview";
import {Subject, takeUntil} from "rxjs";
import {UnsubscriberService} from "../../services/unsubscriber.service";

@Component({
  selector: 'app-secure-links-home',
  standalone: true,
  providers: [UnsubscriberService],
  imports: [
    SecureLinksTableComponent,
    TabViewModule
  ],
  templateUrl: './secure-links-home.component.html',
  styleUrl: './secure-links-home.component.css'
})
export class SecureLinksHomeComponent implements OnInit {
  secureLinks: any = [];
  private unsubService = inject(UnsubscriberService);

  constructor(private entityService: EntityService) {
  }

  ngOnInit() {
    this.entityService.entities.pipe(this.unsubService.takeUntilDestroy).subscribe((entities: Entity[]) => {
      if (!this.entityService.entitiesLoaded) {
        this.entityService.getAllEntities();
      }

      if (entities && entities.length) {
        let links: any = [];
        entities.forEach(entity => {
          if (entity.magicLinks?.length) {
            entity.magicLinks.forEach((link) => {
              link.entity = entity;
            });
            links = [...links, ...entity.magicLinks!]
          }
        });
        this.secureLinks = links;
      }
    })
  }

}
