
export const MSWORD_MIMETYPE = 'application/msword';
export const DOCXWORD_MIMETYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const PNG_MIMETYPE = 'image/png';
export const JPEG_MIMETYPE = 'image/jpeg';
export const JPG_MIMETYPE = 'image/jpg';
export const BITMAP_MIMETYPE = 'image/bmp';
export const TIFF_MIMETYPE = 'image/tiff';
export const WEBP_MIMETYPE = 'image/webp';
export const ICON_MIMETYPE = 'image/vnd.microsoft.icon';
export const APNG_MIMETYPE = 'application/x-abiword';
export const AVIF_MIMETYPE = 'image/avif';

export const ODS_SPREADSHEET_MIMETYPE = 'application/vnd.oasis.opendocument.spreadsheet';
export const CSV_MIMETYPE = 'text/csv';
export const XLS_MIMETYPE = 'application/vnd.ms-excel';
export const XLSX_MIMETYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const PDF_MIMETYPE = 'application/pdf';

export const AVI_MIMETYPE = 'video/x-msvideo';
export const MP4_MIMETYPE = 'video/mp4';
export const MPEG_MIMETYPE = 'video/mpeg';
export const OGG_VIDEO_MIMETYPE = 'video/ogg';
export const WEBP_VIDEO_MIMETYPE = 'video/webm';
export const TS_MIMETYPE = 'video/mp2t';
export const GPP_MIMETYPE = 'video/3gpp';
export const GPP2_MIMETYPE = 'video/3gpp2'

export const AAC_MIMETYPE = 'audio/acc';
export const MP3_MIMETYPE = 'audio/mpeg';
export const OGG_AUDIO_MIMETYPE = 'audio/ogg';
export const WAV_MIMETYPE = 'audio/wav';
export const WEBM_AUDIO_MIMETYPE = 'audio/webm';
export const GPP_AUDIO_MIMETYPE = 'audio/3gpp';
export const GPP2_AUDIO_MIMETYPE = 'audio/3gpp2';

export const ODP_PRESENTATION_MIMETYPE = 'application/vnd.oasis.opendocument.presentation';
export const MICROSOFT_POWERPOINT_MIMETYPE = 'application/vnd.ms-powerpoint';
export const MICROSOFT_POWERPOINT_OPEN_MIMETYPE = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';




export const WORDMIMETYPES = [MSWORD_MIMETYPE, DOCXWORD_MIMETYPE];
export const IMAGEMIMETYPES = [PNG_MIMETYPE, JPEG_MIMETYPE, JPG_MIMETYPE, BITMAP_MIMETYPE, TIFF_MIMETYPE, WEBP_MIMETYPE, ICON_MIMETYPE, APNG_MIMETYPE, AVIF_MIMETYPE];
export const SPREADSHEETMIMETYPES = [ODS_SPREADSHEET_MIMETYPE, CSV_MIMETYPE, XLS_MIMETYPE, XLSX_MIMETYPE];
export const VIDEOMIMETYPES = [AVI_MIMETYPE, MP4_MIMETYPE, MPEG_MIMETYPE, OGG_VIDEO_MIMETYPE, WEBP_VIDEO_MIMETYPE, TS_MIMETYPE, GPP2_MIMETYPE, GPP_MIMETYPE];
export const AUDIOMIMETYPES = [AAC_MIMETYPE, MP3_MIMETYPE, OGG_AUDIO_MIMETYPE, WAV_MIMETYPE, WEBM_AUDIO_MIMETYPE, GPP_AUDIO_MIMETYPE, GPP2_AUDIO_MIMETYPE];
export const POWERPOINTMIMETYPES = [ODP_PRESENTATION_MIMETYPE, MICROSOFT_POWERPOINT_MIMETYPE, MICROSOFT_POWERPOINT_OPEN_MIMETYPE];


