<div>
  <p-table
    [value]="displayFilteredStatus === 'expired' ? expiredLinks : activeLinks"
    styleClass="p-datatable-striped"
    [scrollable]="true"
    [loading]="loading"
    scrollHeight="80vh"
    [tableStyle]="{'min-width': '50vw'}">
    <ng-template pTemplate="header">
      <tr>
        <th>Created</th>
        <th *ngIf="displayEntity">Entity</th>
        <th>Link</th>
        <th>Expires</th>
        <th class="text-center">Sensitive Info</th>
        <th>Documents</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-link>
      <tr>
        <td><span class="text-sm font-bold text-color-secondary">{{link.createdAt | date : 'short' }}</span></td>
        <td *ngIf="displayEntity">{{link.entity?.name}}</td>
        <td  class="p-link underline text-blue-600 ">
          <div class="flex align-items-center">
            <p class="my-0">
              {{environment.appUrl}}/link/{{link.token}}
            </p>
            <p-button icon="pi pi-copy" [rounded]="true" [cdkCopyToClipboard]="environment.appUrl+'/link/'+link.token" [text]="true" />
          </div>
        </td>
        <td ><span class="text-sm">{{link.expirationDate | date: 'mediumDate'}}</span></td>
        <td class="text-center"><i class="pi" [ngClass]="link.exposeSensitiveInfo ? 'pi-check text-primary' : 'pi-times'"></i></td>
        <td>
          <div class="py-2">
            <p-chip *ngIf="link.folders?.length" (click)="folderOverlay.toggle($event)" styleClass="cursor-pointer">
              <span class="pi pi-folder"></span>
              <span class="mx-2">Folders</span>
              <p-badge [value]="link.folders.length" />
            </p-chip>
            <p-overlayPanel #folderOverlay>
              <p-scrollPanel [style]="{ width: '100%', 'height': '125px' }">
                <div class="flex flex-column gap-1">
                  <p class="mt-0 mb-1 border-bottom-1 border-gray-200 pb-2" *ngFor="let folder of link.folders">{{folder.name}}</p>
                </div>
              </p-scrollPanel>
            </p-overlayPanel>
            <p-chip *ngIf="link.documents?.length" styleClass="mt-2 cursor-pointer" (click)="documentOverlay.toggle($event)">
              <span class="pi pi-file"></span>
              <span class="mx-2">Documents</span>
              <p-badge [value]="link.documents.length" />
            </p-chip>
            <p-overlayPanel #documentOverlay>
              <p-scrollPanel [style]="{ width: '100%', 'height': '125px' }">
                <div class="flex flex-column gap-1">
                  <p class="mt-0 mb-1 pb-2 border-bottom-1 border-gray-200" *ngFor="let doc of link.documents">{{doc.name}}</p>
                </div>
              </p-scrollPanel>
            </p-overlayPanel>
            <ng-template #documentTooltip>
              <div class="flex align-items-center">
                <span> <b>PrimeNG</b> rocks! </span>
              </div>
            </ng-template>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
      <tr *ngFor="let row of [1,2,3,4,5]">
        <td><p-skeleton></p-skeleton></td>
        <td><p-skeleton></p-skeleton></td>
        <td><p-skeleton></p-skeleton></td>
        <td><p-skeleton></p-skeleton></td>
      </tr>
    </ng-template>
  </p-table>
</div>
