import {Component, OnInit} from '@angular/core';
import {TabViewModule} from "primeng/tabview";
import {NgForOf} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {EntityHomeDefaultView} from "../../models/entity.model";
import {ProfileSettingsComponent} from "./profile-settings/profile-settings.component";
import {UserManagementSettingsComponent} from "./user-management-settings/user-management-settings.component";
import {SubscriptionSettingsComponent} from "./subscription-settings/subscription-settings.component";

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    TabViewModule,
    NgForOf,
    ProfileSettingsComponent,
    UserManagementSettingsComponent,
    SubscriptionSettingsComponent
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent implements OnInit {
  activeIndex = 0;
  tabs = [
    { title: 'Profile', key: 'profile'},
    { title: 'User Management', key: 'users'},
    { title: 'Billing', key: 'billing'}
  ];

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params && params['tab']) {
        const tabKey = params['tab'];
        this.activeIndex = this.tabs.findIndex((tab) => tab.key === tabKey);
        this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams: {tab: null},
            queryParamsHandling: 'merge', // remove to replace all query params by provided
          }
        );
      }
    });
  }

}
