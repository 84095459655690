<div>
  <h3 class="mb-3 text-black-alpha-90">Secure Links</h3>
  <p-tabView>
    <p-tabPanel header="Active Links">
      <div class="flex align-items-center gap-3 mb-5">
        <app-secure-links-table [secureLinks]="secureLinks" [displayEntity]="true" [displayFilteredStatus]="'active'"></app-secure-links-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Expired Links">
      <div class="flex align-items-center gap-3 mb-5">
        <app-secure-links-table [secureLinks]="secureLinks" [displayEntity]="true" [displayFilteredStatus]="'expired'"></app-secure-links-table>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
