<p-fileUpload name="myfile[]" [multiple]="true" accept="image/*, application/pdf" [maxFileSize]="30000000" (onUpload)="onTemplatedUpload()" (onSelect)="onSelectedFiles($event)">
  <ng-template pTemplate="header" let-files let-chooseCallback="chooseCallback" let-clearCallback="clearCallback" let-uploadCallback="uploadCallback">
    <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
      <div class="flex gap-2">
        <p-button (onClick)="choose($event, chooseCallback)" icon="pi pi-images" [rounded]="true" [outlined]="true" />
        <p-button (onClick)="uploadFiles($event)" icon="pi pi-cloud-upload" [rounded]="true" [outlined]="true" severity="success" [disabled]="!files || files.length === 0" />
        <p-button (onClick)="clearCallback()" icon="pi pi-times" [rounded]="true" [outlined]="true" severity="danger" [disabled]="!files || files.length === 0" />
      </div>
      <p-progressBar [value]="totalSizePercent" [showValue]="false" styleClass="md:w-20rem h-1rem w-full md:ml-auto" [ngClass]="{ 'exceeded-progress-bar': totalSizePercent > 100 }">
        <span class="white-space-nowrap">{{ totalSize }}B / 1Mb</span>
      </p-progressBar>
    </div>
  </ng-template>
  <ng-template pTemplate="content" let-files let-uploadedFiles="uploadedFiles" let-removeFileCallback="removeFileCallback" let-removeUploadedFileCallback="removeUploadedFileCallback">
    <div *ngIf="files?.length > 0">
      <h5>Pending</h5>
      <div class="flex flex-wrap p-0 sm:p-5 gap-5">
        <div *ngFor="let file of files; let i = index" class="card m-0 p-2 flex justify-content-between border-1 flex-wrap surface-border align-items-center gap-3 w-full">
          <div>
            <img role="presentation" [alt]="file.name" [src]="file.objectURL" width="100" height="50" />
          </div>
          <span [pTooltip]="file.name" class="font-semibold text-sm text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-15rem">{{ file.name }}</span>
          <div class="text-xs">{{ formatSize(file.size) }}</div>
<!--          <p-badge value="Pending" severity="warning" />-->
          <p-treeSelect
            class="md:w-20rem w-full"
            containerStyleClass="w-full"
            (onNodeSelect)="updateDocumentFolderSelection($event, file.name)"
            [options]="folders"
            appendTo="body"
            [virtualScroll]="true"
            [virtualScrollItemSize]="46"
            [virtualScrollOptions]="{scrollHeight: '200px'}"
            placeholder="Select Folder" />
          <p-button icon="pi pi-times" (click)="onRemoveTemplatingFile($event, file, removeFileCallback, i)" [outlined]="true" [rounded]="true" severity="danger" />
        </div>
      </div>
      <div class="text-right">
        <p-button label="Save Files" (onClick)="uploadFiles($event)"></p-button>
      </div>
    </div>
    <div *ngIf="uploadedFiles?.length > 0">
      <h5>Completed</h5>
      <div class="flex flex-wrap p-0 sm:p-5 gap-5">
        <div *ngFor="let file of uploadedFiles; let i = index" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
          <div>
            <img role="presentation" [alt]="file.name" [src]="file.objectURL" width="100" height="50" />
          </div>
          <span class="font-semibold">{{ file.name }}</span>
          <div>{{ formatSize(file.size) }}</div>
          <p-badge value="Completed" class="mt-3" severity="success" />
          <p-button icon="pi pi-times" (onClick)="removeUploadedFileCallback(i)" [outlined]="true" [rounded]="true" severity="danger" />
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="file"> </ng-template>
  <ng-template pTemplate="empty" >
    <div class="flex align-items-center justify-content-center flex-column">
      <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400"></i>
      <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
    </div>
  </ng-template>
</p-fileUpload>
