import {Account} from "./account.model";
import {FelixUser} from "./user.model";

export enum SubscriptionStatus {
  ACTIVE= "ACTIVE",
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
  CANCELED = "CANCELED",
  PAST_DUE = "PAST_DUE",
  TRIALING = "TRIALING",
  UNPAID = "UNPAID"
}


export interface Subscription {
  id: number;
  stripeSubscriptionId: string;
  accountId: number;
  account: Account;
  userId: number;
  user?: FelixUser;
  status: SubscriptionStatus;
  currentPeriodStart: Date;
  currentPeriodEnd: Date;
  createdAt: Date;
  updatedAt: Date;
  payments: Payment[];
  product?: Product;
}

export interface Payment {
  id: number;
  stripePaymentId: string;
  subscriptionId: number;
  subscription: Subscription;
  amount: number;
  currency: string;
  createdAt : Date;
}

export interface Product{
  productId: number;
  name: string;
  stripeProductId?: string;
  created: Date;
}
