import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {Entity, EntityType} from "../../../models/entity.model";
import {CheckboxModule} from "primeng/checkbox";
import {DocumentsTableComponent} from "../documents-table/documents-table.component";
import {CalendarModule} from "primeng/calendar";
import {SecurelinkService} from "../../../services/securelink.service";

@Component({
  selector: 'app-create-secure-link',
  standalone: true,
  imports: [
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    ScrollPanelModule,
    CheckboxModule,
    DocumentsTableComponent,
    CalendarModule
  ],
  templateUrl: './create-secure-link.component.html',
  styleUrl: './create-secure-link.component.css'
})
export class CreateSecureLinkComponent {
  @ViewChild('docsTable') docsTable: DocumentsTableComponent | undefined;
  @Input() entity: Entity | undefined;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  magicLinkForm: FormGroup;
  loading = false;
  constructor(private fb: FormBuilder, private magicLinkService: SecurelinkService) {
    this.magicLinkForm = this.fb.group({
      expirationDate: [undefined, Validators.required],
      exposeSensitiveInfo: [false, Validators.required],
      documentIds: [[], Validators.required],
      folderIds: [[], Validators.required],
      entityId: ["", Validators.required],
    });
  }

  createSecureLink() {
    this.loading = true;
    const selectedDocs = this.docsTable?.selectedTreeNodes!;
    const folderIds = selectedDocs.filter((doc) => doc.data.type === 'folder').map((doc) => doc.data.folderId);
    const documentIds = selectedDocs.filter((doc) => {
      return doc.data.type === 'document' && !folderIds.includes(doc.parent?.data.folderId)
    }).map((doc) => doc.data.documentId);
    this.magicLinkService.createSecureLink({
      entityId: this.entity?.entityId!,
      folderIds,
      documentIds,
      exposeSensitiveInfo: this.magicLinkForm.value.exposeSensitiveInfo,
      expirationDate: this.magicLinkForm.value.expirationDate
    }).subscribe((res) => {
      this.loading = false;
      if (res) {
        this.entity?.magicLinks!.push(res);
        this.onClose.emit();
      }
    })
  }

}
