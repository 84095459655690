import { Routes } from '@angular/router';
import {EntitiesHomeComponent} from "./components/entities-home/entities-home.component";
import {EntityDetailComponent} from "./components/entity-detail/entity-detail.component";
import {LoginComponent} from "./components/login/login.component";
import {AuthService} from "./services/auth.service";
import {inject} from "@angular/core";
import {authGuard} from "./guards/auth.guard";
import {RegisterComponent} from "./components/register/register.component";
import {SecureLinkLandingPageComponent} from "./components/secure-link-landing-page/secure-link-landing-page.component";
import {SecureLinksHomeComponent} from "./components/secure-links-home/secure-links-home.component";
import {SettingsComponent} from "./components/settings/settings.component";
import {DocumentLogsHomeComponent} from "./components/document-logs-home/document-logs-home.component";
import {activeSubscriptionGuard} from "./guards/subscription.guard";
import {PlanSelectionComponent} from "./components/plan-selection/plan-selection.component";

export const routes: Routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'register', component: RegisterComponent, pathMatch: 'full' },
  { path: 'home', component: EntitiesHomeComponent, canActivate: [authGuard, activeSubscriptionGuard("plan-selection")] },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'entities/:entityId', component: EntityDetailComponent, canActivate: [authGuard, activeSubscriptionGuard("plan-selection")] },
  { path: 'secure-links', component: SecureLinksHomeComponent, canActivate: [authGuard, activeSubscriptionGuard("plan-selection")] },
  { path: 'sent-documents', component: DocumentLogsHomeComponent, canActivate: [authGuard, activeSubscriptionGuard("plan-selection")] },
  { path: 'settings', component: SettingsComponent, canActivate: [authGuard] },
  { path: 'plan-selection', component: PlanSelectionComponent, canActivate: [authGuard] },
  { path: 'link/:token', component: SecureLinkLandingPageComponent},
];
