import {Component, Input, OnInit} from '@angular/core';
import {TableModule} from "primeng/table";
import {SecureLink} from "../../../models/securelink.model";
import {SecurelinkService} from "../../../services/securelink.service";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {Button} from "primeng/button";
import {ChipModule} from "primeng/chip";
import {BadgeModule} from "primeng/badge";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {TooltipModule} from "primeng/tooltip";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {environment} from "../../../../environments/environment";
import {SkeletonModule} from "primeng/skeleton";

@Component({
  selector: 'app-secure-links-table',
  standalone: true,
  imports: [
    TableModule,
    DatePipe,
    Button,
    NgClass,
    ChipModule,
    BadgeModule,
    CdkCopyToClipboard,
    TooltipModule,
    NgForOf,
    OverlayPanelModule,
    ScrollPanelModule,
    NgIf,
    SkeletonModule
  ],
  templateUrl: './secure-links-table.component.html',
  styleUrl: './secure-links-table.component.css'
})
export class SecureLinksTableComponent implements OnInit{
  @Input() displayFilteredStatus: 'active' | 'expired' | undefined;
  @Input()
  set secureLinks(links: SecureLink[]) {
    this.expiredLinks = links?.filter((link) => this.isExpired(link.expirationDate));
    this.activeLinks = links?.filter((link) => !this.isExpired(link.expirationDate));
  }
  @Input() displayEntity: boolean = false;
  loading = false;
  activeLinks: SecureLink[] = [];
  expiredLinks: SecureLink[] = [];
  constructor() {
  }

  ngOnInit(): void {

  }

  isExpired(expirationDate) {
    //check if link is expired.
    return new Date(expirationDate) < new Date()
  }


  protected readonly environment = environment;
}
