<div>
  <div>
    <h4>Account Display Name</h4>
    <p-inplace #accountNameInPlace>
      <ng-template pTemplate="display">
        <div class="inline-flex align-items-center">
          <span class="pi pi-pencil text-xs" style="vertical-align: middle"></span>
          <span class="ml-2">{{account?.name}}</span>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="max-w-25rem">
          <p-inputGroup>
            <input
              pInputText
              [(ngModel)]="accountName"
              id="accountName"/>
            <p-button type="button" (onClick)="saveProfile()" icon="pi pi-check" [loading]="loading"  class="p-button-plain"></p-button>
          </p-inputGroup>
        </div>
      </ng-template>
    </p-inplace>
    <p-divider></p-divider>
  </div>
  <div>
    <h4>Profile Info</h4>
    <div class="flex">
      <div class="flex flex-column justify-content-center align-items-center">
        <p-avatar
          *ngIf="userDetails?.profileImage"
          [image]="userDetails?.profileImage"
          styleClass="mr-2 mb-3 bg-gray-200 text-black-alpha-90"
          size="xlarge"
          shape="circle" />
        <p-avatar
          *ngIf="!userDetails?.profileImage"
          icon="pi pi-user"
          styleClass="mr-2 mb-3 bg-gray-200 text-black-alpha-90"
          size="xlarge"
          shape="circle" />
        <p-fileUpload
          mode="basic"
          chooseLabel="Upload"
          styleClass="p-button-sm"
          chooseIcon="pi pi-upload"
          name="profileImage[]"
          accept="image/*"
          (onSelect)="onUpload($event)"
          (onUpload)="onUpload($event)"
          maxFileSize="1000000"/>
      </div>
      <p-divider styleClass="mx-5" layout="vertical" />
      <div class="flex flex-column">
      <div class="flex">
        <div class="flex flex-column gap-2 mb-4 mr-4">
          <label for="firstName" class="text-color-secondary mb-2">First Name: </label>
          <p-inplace #profileFirstNameInPlace>
            <ng-template pTemplate="display">
              <div class="inline-flex align-items-center">
                <span class="pi pi-pencil text-xs" style="vertical-align: middle"></span>
                <span class="ml-2">{{userDetails?.firstName}}</span>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <p-inputGroup>
                <input
                  pInputText
                  [(ngModel)]="firstName"
                  id="firstName"/>
                <p-button type="button" (onClick)="saveProfile()" [loading]="loading" icon="pi pi-check" class="p-button-plain"></p-button>
              </p-inputGroup>
            </ng-template>
          </p-inplace>
        </div>
        <div class="flex flex-column gap-2 mb-2">
          <label for="lastName" class="text-color-secondary mb-2">Last Name: </label>
          <p-inplace #profileLastNameInPlace [active]="profileEditMode">
            <ng-template pTemplate="display">
              <div class="inline-flex align-items-center">
                <span class="pi pi-pencil text-xs" style="vertical-align: middle"></span>
                <span class="ml-2">{{userDetails?.lastName}}</span>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <p-inputGroup>
                <input
                  pInputText
                  [(ngModel)]="lastName"
                  id="lastName"/>
                <p-button type="button" (onClick)="saveProfile()" icon="pi pi-check" [loading]="loading" class="p-button-plain"></p-button>
              </p-inputGroup>
            </ng-template>
          </p-inplace>
        </div>
      </div>
      <div class="flex flex-column gap-2 mb-2">
        <label for="email" class="text-color-secondary mb-2">Email: </label>
        <input
          pInputText
          tooltipStyleClass="text-sm"
          pTooltip="To update your email, please contact support."
          [readonly]="true"
          [value]="userDetails?.email"
          [disabled]="true"
          id="email"/>
      </div>
    </div>
  </div>
  <p-divider></p-divider>
<!--  <div>-->
<!--    <h4>Account Name</h4>-->
<!--    <p>Best Account Ever</p>-->
<!--    <p-divider></p-divider>-->
<!--  </div>-->
</div>
