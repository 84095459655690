<div id="bgnd" class="w-full h-full ">
  <div class="flex flex-column align-items-center pt-8">
    <p-card styleClass="max-w-30rem" [style]="{'min-width': '30vw'}">
      <form (ngSubmit)="login()">
        <div class="flex justify-content-between">
          <span class="block mb-5 text-lg text-black-alpha-90">Login</span>
          <span class="p-text-secondary">Need an account? <span [routerLink]="'/register'" class="p-link underline">Register</span></span>
        </div>
        <div class="flex flex-column gap-1 mb-3">
          <label for="name" class="font-semibold">Email Address</label>
          <input pInputText id="name" [ngModelOptions]="{standalone: true}" [(ngModel)]="email" autocomplete="off" />
        </div>
        <div class="flex flex-column gap-1 mb-5">
          <label for="name" class="font-semibold">Password</label>
          <p-password [inputStyle]="{'width':'100%'}" [ngModelOptions]="{standalone: true}" [style]="{'width':'100%'}" [(ngModel)]="password" [feedback]="false" [toggleMask]="true" />
        </div>
        <div class="flex justify-content-end gap-2">
          <p-button type="submit" [disabled]="!email || !password" [loading]="loading" label="Login" (click)="login()"  />
        </div>
      </form>
    </p-card>
  </div>
</div>
