<p-scrollPanel [style]="{ width: '100%'}">
  <form [formGroup]="magicLinkForm" (ngSubmit)="createSecureLink()">
    <div class="flex flex-column gap-2">
      <div>
        <div class="flex flex-column gap-2 mr-4">
          <label for="date" class="font-bold">Expiration Date</label>
          <small id="username-help">
            The date that the link will expire and no longer be valid.
          </small>
          <p-calendar inputId="date" formControlName="expirationDate" />
        </div>
        <div class="flex flex-column gap-2 mt-4">
          <label class="font-bold" for="sensitiveInfo">Documents</label>
          <small>
            Select the folders and documents that can be access using the link.
          </small>
          <app-documents-table #docsTable [showControls]="false" [editMode]="true" [entity]="entity"></app-documents-table>
        </div>
        <div class="flex flex-column gap-2 my-4">
          <label class="font-bold" for="sensitiveInfo">Sensitive Info</label>
          <small>
            Whether the link should display business info like address and ein in addition to documents.
          </small>
          <div class="flex flex-row">
            <p-checkbox
              formControlName="exposeSensitiveInfo"
              class="mr-2"
              [binary]="true"
              inputId="sensitiveInfo" />
            <label for="sensitiveInfo">Expose Sensitive Info</label>
          </div>
        </div>
        <div class="mt-4">
          <p-button (onClick)="createSecureLink()" [loading]="loading"  label="Create Link"></p-button>
        </div>
      </div>
    </div>
  </form>
</p-scrollPanel>
