import {Pipe, PipeTransform} from '@angular/core';
import {DocumentType} from "../models/document.model";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Pipe({ name: 'documentChip', standalone: true })
export class DocumentChipPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(documentType: DocumentType): SafeHtml {
    if (documentType === DocumentType.INCORPORATION_ARTICLES) {
      return this.sanitizer.bypassSecurityTrustHtml(`<p-chip label="Ein" styleClass="ml-4" icon="pi pi-apple" />`);
    }
    if (documentType === DocumentType.EIN_VERIFICATION) {
      return this.sanitizer.bypassSecurityTrustHtml(`<p-chip label="Ein" styleClass="ml-4" icon="pi pi-apple" />`);
    }

    return ``;
  }
}
