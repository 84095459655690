<p-tabView>
  <p-tabPanel header="Feed">
    <div *ngIf="!activities.length">
      <img class="max-w-full" src="notes_bgnd.png"/>
      <p class="text-sm text-color-secondary">Quickly reference important events by recording an activity each time you want to remember something.</p>
    </div>
    <p-scrollPanel *ngIf="activities.length" [style]="{ width: '100%', 'height': '300px' }">
      <div class="pt-3">
        <p-timeline id="activityTimeline" [value]="activities" class="w-full" align="left">
          <ng-template pTemplate="content" let-activity>
            <div class="flex flex-column">
              <small class="p-text-secondary">{{ activity.createdAt | date : 'MM/dd/YYYY' }}</small>
              <div *ngIf="activity.description" [innerHtml]="activity.description | striphtml" class="block mt-2 mb-0 text-sm"></div>
              <div *ngIf="activity.note" (click)="openNote(activity.note)" class="border-1 border-black-alpha-20 my-2 p-2 border-round inline-block hover:text-primary p-text-secondary cursor-pointer hover:bg-primary-50 hover:border-primary">
                <div class="flex align-items-center justify-content-between">
                  <div class="flex align-items-center">
                    <i class="pi pi-file-edit mr-2"></i>
                    <small>{{activity.note.title}}</small>
                  </div>
                </div>
  <!--              <div class="flex align-items-baseline justify-content-between">-->
  <!--                <p [innerHtml]="activity.note.text | truncate | striphtml" class="mt-2 mb-0 text-sm">-->

  <!--                </p>-->
  <!--                <span class="p-link text-sm underline text-color-secondary">View Note</span>-->
  <!--              </div>-->
              </div>
            </div>
          </ng-template>
        </p-timeline>
      </div>

    </p-scrollPanel>
  </p-tabPanel>
  <p-tabPanel header="Notes">
    <div *ngIf="!notes.length">
      <img class="max-w-full" src="notes_bgnd.png"/>
      <p class="text-sm text-color-secondary">Use notes to document long-form information, record conversations or capture meeting notes.</p>
    </div>
    <p-scrollPanel *ngIf="notes.length" [style]="{ width: '100%', 'max-height': '350px' }">
      <div class="my-2" *ngFor="let note of notes">
        <p-card styleClass="border-1 border-black-alpha-20">
          <div class="flex align-items-center justify-content-between">
            <div class="flex align-items-center">
              <i class="pi pi-file-edit mr-2"></i>
              <p class="m-0 text-black">{{note.title}}</p>
            </div>
            <small class="p-text-secondary">{{note.createdAt | date: "short"}}</small>
          </div>
          <div class="flex align-items-baseline justify-content-between">
            <p [innerHtml]="note.text | truncate | striphtml" class="mt-2 mb-0 text-sm flex-grow-1 flex-shrink-1 max-w-fit">
            </p>
            <p class="p-link ml-1 text-sm underline text-color-secondary hover:text-primary flex-shrink-0" (click)="openNote(note)">View Note</p>
          </div>
        </p-card>
      </div>
    </p-scrollPanel>
  </p-tabPanel>
</p-tabView>
