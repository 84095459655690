import {ApplicationConfig, ErrorHandler, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, withRouterConfig} from '@angular/router';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { routes } from './app.routes';
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { getVertexAI, provideVertexAI } from '@angular/fire/vertexai-preview';
import {tokenInterceptor} from "./interceptors/token.interceptor";
import {ConfirmationService, MessageService} from "primeng/api";
import {GlobalErrorHandler} from "./utils/global-error-handler";
import {environment} from "../environments/environment";

export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }), {provide: ErrorHandler, useClass: GlobalErrorHandler}, provideRouter(routes, withRouterConfig({onSameUrlNavigation: 'reload'})), provideAnimations(), provideHttpClient(withInterceptors([tokenInterceptor])), provideFirebaseApp(() => initializeApp(environment.firebase)), provideAuth(() => getAuth()), provideAnalytics(() => getAnalytics()), ScreenTrackingService, UserTrackingService, MessageService, ConfirmationService, provideVertexAI(() => getVertexAI())]
};
