<div>
  <input *ngIf="!viewOnlyMode" type="text" [readOnly]="viewOnlyMode" [(ngModel)]="noteTitle" placeholder="Note Title" class="mb-2 w-25rem" pInputText />
  <p-editor *ngIf="!viewOnlyMode" [(ngModel)]="noteText" [readonly]="viewOnlyMode" placeholder="Some text" [style]="{ height: '65vh' }">
    <ng-template *ngIf="viewOnlyMode" pTemplate="header">
      <h3 *ngIf="viewOnlyMode">{{noteTitle}}</h3>
    </ng-template>
  </p-editor>
  <div *ngIf="viewOnlyMode" class="border-1 border-gray-300 border-round p-3" [style]="{ height: '65vh' }">
    <div [innerHtml]="noteText | safeHtml"></div>
  </div>
  <div *ngIf="!viewOnlyMode" class="mt-2 flex align-items-center justify-content-end">
    <p-button [outlined]="true" styleClass="mr-2" label="Cancel" (onClick)="closeEditor()"></p-button>
    <p-button [disabled]="!noteText || !noteTitle" [loading]="loading" (click)="saveNote()" label="Save Note"></p-button>
  </div>
  <div *ngIf="viewOnlyMode" class="mt-2 flex align-items-center justify-content-end">
    <p-confirmPopup #deleteNote />
    <p-button [outlined]="true" styleClass="mr-2" severity="danger" label="Delete Note" [loading]="deleting" (onClick)="confirmDeleteNote($event)"></p-button>
    <p-button [disabled]="!noteText || !noteTitle || loading" [loading]="loading" (click)="viewOnlyMode = false" label="Edit Note"></p-button>
  </div>
</div>
