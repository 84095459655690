import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AccountRole} from "../models/account.model";
import {environment} from "../../environments/environment";
import {catchError, map, take} from "rxjs/operators";
import {handleError} from "../utils/helpers";
import {Observable} from "rxjs";
import {Subscription} from "../models/subscription.model";

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private apiPrefix = "billing"
  private apiUrl = `${environment.apiUrl}/${this.apiPrefix}`;


  constructor(private router: Router, private http: HttpClient) {}

  //creates a billing session for stripe billing portal
  createBillingSession() : Observable<any> {
    return this.http.get<{data: any}>(this.apiUrl + `/billing-session`)
      .pipe(
        map(response => response.data),
        catchError(handleError<AccountRole>('createBillingSession'))
      );
  }

  //creates a customer session for stripe pricing table
  createCustomerSession() : Observable<any> {
    return this.http.get<{data: any}>(this.apiUrl + `/customer-session`)
      .pipe(
        map(response => response.data),
        catchError(handleError<AccountRole>('createCustomerSession'))
      );
  }

  updateSubscription(subscriptionId: number, updates: {productId: number}) {
    return this.http.put<{data: any}>(this.apiUrl + `/subscriptions/${subscriptionId}`, updates)
      .pipe(
        map(response => response.data),
        catchError(handleError<Subscription>('updateSubscription'))
      );
  }


}
