export const environment = {
  appUrl: "https://dev.app.trustfelix.com/",
  apiUrl: "http://localhost:3000",
  firebase: {
    apiKey: "AIzaSyCyT-jKWvNLhW6cJRX7P2_3HSm9mGk3SNU",
    authDomain: "felix-dev-8a143.firebaseapp.com",
    projectId: "felix-dev-8a143",
    storageBucket: "felix-dev-8a143.appspot.com",
    messagingSenderId: "970164541750",
    appId: "1:970164541750:web:58feae38c740cbbff03b97"
  },
  production: false,
  stripe: {
    publishableKey: "pk_test_51Q0CC82MfSzfwBF6Gnfy4Nnjg2UUuCRxRFmC6R8QpmUW9U6al0igIiNNCDNqdlyYEn97tWx5jZjlxa6tu2uiWC6U00KAikNdo2",
  }
};
