import {Injectable} from "@angular/core";
import {map, Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {Activity, ActivityCreateRequest} from "../models/activity.model";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  private apiPrefix = "activities"
  private apiUrl = `${environment.apiUrl}/${this.apiPrefix}`;
  constructor(private http: HttpClient) {

  }
  // Create a new activity
  createActivity(activity: ActivityCreateRequest): Observable<Activity> {
    return this.http.post<{data: Activity}>(this.apiUrl, activity)
      .pipe(
        map(response => response.data),
        catchError(this.handleError<Activity>('createActivity'))
      );
  }

  // Delete an activity
  deleteActivity(entityId: number, activityId: string): Observable<unknown> {
    const url = `${this.apiUrl}/${activityId}`;
    return this.http.delete(url+`?entityId=${entityId}`)
      .pipe(
        catchError(this.handleError<unknown>('deleteActivity'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      return of(result as T);
    };
  }
}
