import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {map, Observable, of, Subject, BehaviorSubject} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  Entity,
  EntityAttributeKey,
  EntityCreateRequest,
  EntityHomeDefaultView,
  EntityUpdateRequest
} from '../models/entity.model';
import {UserCreateRequest} from "../models/user.model";
import {User} from "@angular/fire/auth";
import {environment} from "../../environments/environment";
import {handleError} from "../utils/helpers";

@Injectable({
  providedIn: 'root'
})
export class EntityService {
  private apiPrefix = "entities"
  private apiUrl = `${environment.apiUrl}/${this.apiPrefix}`;
  public entities: BehaviorSubject<Entity[]> = new BehaviorSubject<Entity[]>([]);
  public entitiesLoaded = false;
  localStorageDefaultViewKey = "defaultEntityView";
  constructor(private http: HttpClient) {
    //this.getAllEntities();
  }

  // Create a new entity
  createEntity(entity: EntityCreateRequest): Observable<Entity> {
    return this.http.post<{data: Entity}>(this.apiUrl, entity)
      .pipe(
        map(response => response.data),
        catchError(this.handleError<Entity>('createEntity'))
      );
  }

  // Get all entities
  getAllEntities(): void {
    this.http.get<{data: Entity[]}>(this.apiUrl)
      .pipe(
        map(response => response.data),
        catchError(this.handleError<Entity[]>('getAllEntities', []))
      ).subscribe(res => {
        this.entitiesLoaded = true;
        this.entities.next(res);
    })
  }

  // Get an entity
  getEntityAttributeKeys(): Observable<EntityAttributeKey[]> {
    const url = `${this.apiUrl}/attributes/keys`;
    return this.http.get<{data: EntityAttributeKey[]}>(url)
      .pipe(
        map(response => response.data),
        catchError(this.handleError<EntityAttributeKey[]>('getEntityAttributeKeys'))
      );
  }

  // Update an entity
  updateEntity(id: number, entity: EntityUpdateRequest): Observable<Entity> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.put<{data: Entity}>(url, entity)
      .pipe(
        map(response => response.data),
        catchError(this.handleError<Entity>('updateEntity'))
      );
  }

  // Delete an entity
  deleteEntity(id: number): Observable<unknown> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete(url)
      .pipe(
        catchError(this.handleError<unknown>('deleteEntity'))
      );
  }

  // Get entity meta data options
  getEntity(entityId: number): void {
    const url = `${this.apiUrl}/${entityId}`;
    this.http.get<{data: Entity}>(url)
      .pipe(
        map(response => response.data),
        catchError(this.handleError<Entity>('getEntity', undefined))
      ).subscribe(res => {
      const currentEntities = this.entities.value;
      const removeIndex = this.entities.value.map((e) => e.entityId).indexOf(entityId);
      currentEntities.splice(removeIndex, 1, res);
      this.entities.next(currentEntities);
    })
  }

  // Determines how to display entities on the home page
  getDefaultEntityHomeView() : EntityHomeDefaultView {
    return localStorage.getItem(this.localStorageDefaultViewKey) as EntityHomeDefaultView || EntityHomeDefaultView.list;
  }

  // Determines how to display entities on the home page
  setDefaultEntityHomeView(view: EntityHomeDefaultView) {
    localStorage.setItem(this.localStorageDefaultViewKey, view);
  }

  // Handle HTTP operation that failed.
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      return of(result as T);
    };
  }

  //registers a new user and creates an account
  register(data: UserCreateRequest) : Observable<User> {
    return this.http.post<{data: User}>(environment.apiUrl + "users", data)
      .pipe(
        map(response => response.data),
        catchError(this.handleError<User>('registerUser'))
      );
  }
}
