import {Component, inject} from '@angular/core';
import {Button} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {TreeSelectModule} from "primeng/treeselect";
import {PasswordModule} from "primeng/password";
import {FormsModule} from "@angular/forms";
import {Auth, authState, getAuth, signInWithEmailAndPassword} from "@angular/fire/auth";
import {Subscription, throwError} from "rxjs";
import {User} from "@angular/fire/auth";
import {CardModule} from "primeng/card";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {UnsubscriberService} from "../../services/unsubscriber.service";
import {handleError} from "../../utils/helpers";
import {catchError} from "rxjs/operators";

@Component({
  selector: 'app-login',
  standalone: true,
  providers: [UnsubscriberService],
  imports: [
    Button,
    InputTextModule,
    TreeSelectModule,
    PasswordModule,
    FormsModule,
    CardModule,
    RouterLink
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  private auth = inject(Auth);
  private authService = inject(AuthService);
  private route = inject(ActivatedRoute);
  private unsubService = inject(UnsubscriberService);
  private router = inject(Router);
  authState$ = authState(this.auth);
  authStateSubscription: Subscription;
  email;
  password;
  isLoggedIn: any = null;
  returnUrl: string = "/home";
  loading = false;
  constructor() {
    this.authStateSubscription = this.authState$.pipe(this.unsubService.takeUntilDestroy).subscribe((aUser: User | null) => {
      this.isLoggedIn = aUser;
    });
    this.route.queryParams.pipe(this.unsubService.takeUntilDestroy).subscribe(params => {
      if (params['returnUrl']) {
        this.returnUrl = params['returnUrl'];
      }
    });
  }

  login() {
    this.loading = true;
    this.authService.login(this.email, this.password).then(async () => {
      this.authService.getUserDetails().subscribe();
      await this.router.navigate([this.returnUrl]);
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      throw err;
    })
  }
}
