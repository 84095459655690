
<p-tree
  *ngIf="!showControls"
  [value]="filteredDocuments()"
  scrollHeight="325px"
  class="w-full"
  [selectionMode]="'checkbox'"
  [propagateSelectionUp]="true"
  [propagateSelectionDown]="false"
  [(selection)]="selectedTreeNodes">

  <ng-template pTemplate="header">
  </ng-template>

  <ng-template pTemplate="default" let-node>
    <div class="flex w-24rem align-items-center justify-content-between"><span>{{node.label}}</span><span class="mr-5">{{node.data.size | fileSize}}</span></div>
  </ng-template>

  <ng-template pTemplate="empty">
    <p>No Available Documents</p>
  </ng-template>
</p-tree>

<p-treeTable *ngIf="showControls" #docsTable [value]="documents" [contextMenu]="documentOptions" [(contextMenuSelection)]="selectedTreeNode"  [columns]="cols" [virtualScroll]="true" [virtualScrollItemSize]="45"  scrollHeight="400px" [selectionMode]="editMode ? 'checkbox' : 'single'" [(selection)]="selectedTreeNodes" dataKey="key" styleClass="p-treetable-sm">
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between align-items-center">
        <div *ngIf="showControls" class="text-xl font-bold">Documents </div>
        <div  *ngIf="showControls && !editMode">
          <p-button icon="pi pi-cog" styleClass="mr-2" [text]="true" label="Manage Bulk" (click)="editMode = !editMode" severity="info" />
          <p-button icon="pi pi-folder-plus" styleClass="mr-2" [text]="true" label="New Folder" (click)="showNewFolderDialog = true" severity="info" />
          <p-button icon="pi pi-file-arrow-up" label="Upload" [text]="true" (click)="showUploadDialog = true" severity="info" />
        </div>
        <div *ngIf="showControls && editMode">
          <p-button icon="pi pi-times" styleClass="mr-2" [text]="true" label="Cancel" (click)="editMode = !editMode" severity="warning" />
<!--          <p-button icon="pi pi-pencil" label="Rename" [disabled]="selectedTreeNodes?.length !== 1" [text]="true" (click)="showRenameDialog = true" severity="warning" />-->
          <p-button icon="pi pi-trash" [disabled]="!selectedTreeNodes.length" styleClass="mr-2 danger" [text]="true" label="Delete" (onClick)="confirmDeleteDocument()" severity="danger" />
          <p-button icon="pi pi-folder-open" label="Move" [disabled]="!selectedTreeNodes.length" [text]="true" (click)="showMoveFolderDialog = true" severity="warning" />
        </div>
      </div>
    </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
        {{ col.header }}
      </th>
    </tr>
    <p-confirmDialog key="deleteConfirm" />
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
    <tr pDraggable pDroppable dragEffect="move" (onDragEnter)="handleOnDragEnter($event, rowNode.node.key)"
        (onDragStart)="handleOnDrag($event, rowNode)" (onDrop)="handleOnDrop($event, rowNode)" [ttContextMenuRow]="rowNode"
        [ttRow]="rowNode" [ttSelectableRow]="rowNode" (dblclick)="nodeSelect($event)" [class.bg-green-50]="highlightDrop === rowNode.node.key">
      <td *ngFor="let col of columns; let i = index; let last = last" [ngStyle]="{'width': col.width}" [class.text-right]="last" [class.text-sm]="col.field === 'updated'">
<!--        <p-treeTableCheckbox *ngIf="editMode && i === 0" [value]="rowNode" />-->
        <div class="flex align-items-center" *ngIf="col.field === 'name'">
          <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0" />
          <p-checkbox *ngIf="editMode && i === 0" [binary]="true" styleClass="vertical-align-text-bottom mr-2" [ngModel]="isRowSelected(rowNode)" (click)="toggleRowSelection(rowNode)">
          </p-checkbox>
          <span *ngIf="i === 0 && rowData.type === 'document'" [class]="rowData['fileType'] | mimeIcon"></span>
          <span *ngIf="i === 0 && rowData.type === 'folder'" class="pi pi-folder"></span>
          <span tooltipStyleClass="text-sm" showDelay="500" [pTooltip]="rowData[col.field]" class="p-text-secondary text-overflow-ellipsis ml-2 overflow-hidden"> {{ rowData[col.field] }}
            <span *ngIf="rowData.documentType && rowData.documentType !== DocumentType.GENERAL"><p-tag severity="secondary" styleClass="ml-2" [value]="rowData.documentType === DocumentType.INCORPORATION_ARTICLES ? 'Incorporation Articles' : 'EIN'" [icon]="rowData.documentType === DocumentType.INCORPORATION_ARTICLES ? 'pi pi-receipt' : 'pi pi-building-columns'" />
          </span>
        </span>
        </div>
        <span *ngIf="!['name','fileType','updated','size'].includes(col.field)"> {{ rowData[col.field] }}</span>
        <span class="p-text-secondary" *ngIf="col.field === 'size'"> {{ rowData[col.field] | fileSize }}</span>
        <span class="p-text-secondary" *ngIf="col.field === 'fileType'"> {{ rowData[col.field]  | mimeRename }}</span>
        <span class="p-text-secondary" *ngIf="col.field === 'updated'"> {{ rowData[col.field]  | date : 'MMM d, y h:mm a' }}</span>

        <ng-container *ngIf="last">
          <p-button icon="pi pi-ellipsis-v" (onClick)="this.selectedTreeNode = rowNode.node;tableActionmenu.toggle($event)" [rounded]="true" [text]="true" />
          <p-menu
            styleClass="text-14-500-20"
            appendTo="body"
            #tableActionmenu
            [popup]="true"
            [model]="documentRowOptionsMenu"
          ></p-menu>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template  pTemplate="footer">
    <tr pDroppable (onDragEnter)="handleOnDragEnter($event, 'footer')" (onDrop)="handleOnDrop($event, null)" class="p-2">
      <td [class.bg-green-200]="highlightDrop === 'footer'"></td>
      <td [class.bg-green-200]="highlightDrop === 'footer'"></td>
      <td [class.bg-green-200]="highlightDrop === 'footer'"></td>
      <td [class.bg-green-200]="highlightDrop === 'footer'"></td>
      <td [class.bg-green-200]="highlightDrop === 'footer'"></td>
    </tr>
    <!--          <div style="text-align:right">-->
    <!--            <p-button icon="pi pi-upload" label="Upload" severity="warning" />-->
    <!--          </div>-->
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <td width="100%">
    <div class="text-center p-3">
<!--      <img class="max-w-full" src="notes_bgnd.png"/>-->
      <img class="max-w-full opacity-50 max-w-15rem" src="docs_bgnd.png"/>
      <p class="text-sm text-color-secondary">Upload and organize all of your important documents here.</p>
    </div>
    </td>
  </ng-template>
</p-treeTable>
<p-contextMenu #documentOptions [model]="documentRowOptionsMenu" />

<p-dialog [(visible)]="showUploadDialog" [style]="{ width: '60vw' }"  [modal]="true" styleClass="mi">
  <app-documents-upload *ngIf="entity" [folders]="availableFolders" [entityId]="entity!.entityId" (documentsUploaded)="showUploadDialog = false;refreshEntity();"></app-documents-upload>
</p-dialog>

<p-dialog [(visible)]="showNewFolderDialog" header="New Folder" [style]="{ width: '25rem' }" [modal]="true">
  <form (ngSubmit)="createFolder()">
    <span class="p-text-secondary block mb-5">Create a new folder.</span>
    <div class="flex flex-column gap-2 mb-3">
      <label for="name" class="font-semibold">Folder Name</label>
      <input pInputText id="name" class="flex-auto" [(ngModel)]="folderName" [ngModelOptions]="{standalone: true}" autocomplete="off" />
    </div>
    <div class="flex flex-column gap-2 mb-5">
      <label for="parent" class="font-semibold">Parent Folder</label>
      <p-treeSelect
        class="md:w-20rem w-full"
        [options]="availableFolders"
        [(ngModel)]="parentFolder"
        [ngModelOptions]="{standalone: true}"
        containerStyleClass="w-full"
        [showClear]="true"
        appendTo="body"
        id="parent"
        placeholder="Select Parent" />
    </div>
    <div class="flex justify-content-end gap-2">
      <p-button label="Cancel" [loading]="loadingCreateFolder" (onClick)="showNewFolderDialog = false; folderName = ''; parentFolder = undefined" severity="secondary"/>
      <p-button  [disabled]="!folderName" (onClick)="createFolder()" label="Save"/>
    </div>
  </form>
</p-dialog>

<p-dialog [(visible)]="showMoveFolderDialog" header="Move Documents" [style]="{ width: '25rem' }" [modal]="true">
  <form>
    <span *ngIf="selectedTreeNode" class="p-text-secondary block mb-5">{{selectedTreeNode.label}}</span>
    <span class="p-text-secondary block mb-5">Move to a new folder.</span>
    <div class="flex flex-column gap-2 mb-5">
      <label class="font-semibold">Select Folder: </label>
      <p-treeSelect
        class="md:w-20rem w-full"
        [options]="availableFolders"
        [(ngModel)]="moveToFolder"
        [showClear]="true"
        [ngModelOptions]="{standalone: true}"
        containerStyleClass="w-full"
        appendTo="body"
        placeholder="Select" />
    </div>
    <div class="flex justify-content-end gap-2">
      <p-button label="Cancel" (onClick)="showMoveFolderDialog = false; folderName = ''; moveToFolder = undefined" severity="secondary"/>
      <p-button  [disabled]="!moveToFolder" (onClick)="handleDocumentMove()" label="Save"/>
    </div>
  </form>
</p-dialog>

<p-dialog [(visible)]="showRenameDialog" header="Rename Document" [style]="{ width: '25rem' }" [modal]="true">
    <span *ngIf="selectedTreeNode" class="p-text-secondary block mb-5">{{selectedTreeNode.label}}</span>
    <div class="flex flex-column gap-2 mb-5">
      <label class="font-semibold">New Name: </label>
      <input
        #newName
        [(ngModel)]="newDocumentFolderName"
        pInputText
        aria-describedby="username-help"
      />
    </div>
    <div class="flex justify-content-end gap-2">
      <p-button label="Cancel" (onClick)="showRenameDialog = false; folderName = ''; parentFolder = undefined" severity="secondary"/>
      <p-button [loading]="updatingDocument"  [disabled]="!newName.value" (onClick)="handleDocumentRename(selectedTreeNode, newName.value)" label="Save"/>
    </div>
</p-dialog>
