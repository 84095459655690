import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {Entity, EntityType} from "../../../models/entity.model";
import {CheckboxModule} from "primeng/checkbox";
import {DocumentsTableComponent} from "../documents-table/documents-table.component";
import {CalendarModule} from "primeng/calendar";
import {SecurelinkService} from "../../../services/securelink.service";
import {ChipsModule} from "primeng/chips";
import {InputTextareaModule} from "primeng/inputtextarea";
import {DocumentService} from "../../../services/document.service";
import {NgClass, NgIf, NgStyle} from "@angular/common";
import {DocumentType} from "../../../models/document.model";
import {Note} from "../../../models/activity.model";
import {DividerModule} from "primeng/divider";

@Component({
  selector: 'app-send-documents',
  standalone: true,
  imports: [
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    ScrollPanelModule,
    CheckboxModule,
    DocumentsTableComponent,
    CalendarModule,
    ChipsModule,
    InputTextareaModule,
    NgStyle,
    NgClass,
    NgIf,
    DividerModule
  ],
  templateUrl: './send-documents.component.html',
  styleUrl: './send-documents.component.css'
})
export class SendDocumentsComponent implements OnInit {
  @ViewChild('docsTable') docsTable: DocumentsTableComponent | undefined;
  @Input()
  set entity(entity: Entity | undefined) {
    if (entity) {
      this._entity = entity;
      if (this._entity){
        this.einVerificationDocument =  this._entity.documents?.find((document) => document.documentType === DocumentType.EIN_VERIFICATION);
      }
      if (this._entity){
        this.articlesOfIncorporationDocument = this._entity.documents?.find((document) => document.documentType === DocumentType.INCORPORATION_ARTICLES);
      }
    }
  }
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  _entity: Entity | undefined;
  sendDocumentsForm: FormGroup;
  loading = false;
  placeholderMessage = "Zach Bowers has shared the attached documents with you.";
  includeEinVerification = false;
  includeIncorporationArticles = false;
  einVerificationDocument;
  articlesOfIncorporationDocument;
  constructor(private fb: FormBuilder, private documentService: DocumentService) {
    this.sendDocumentsForm = this.fb.group({
      emailAddresses: [undefined, Validators.required],
      message: [undefined],
      documentIds: [],
      folderIds: [],
    });
  }

  ngOnInit() {

  }

  submit() {
    this.loading = true;
    const selectedDocs = this.docsTable?.selectedTreeNodes!;
    const folderIds = selectedDocs.filter((doc) => doc.data.type === 'folder' && !selectedDocs.find((f) => f.data.type==='folder' && f.data.folderId === doc.parent?.data.folderId)).map((doc) => doc.data.folderId);
    const documentIds = selectedDocs.filter((doc) => {
      return doc.data.type === 'document' && !folderIds.includes(doc.parent?.data.folderId)
    }).map((doc) => doc.data.documentId);
    if (this.includeEinVerification) {
      documentIds.push(this.einVerificationDocument?.documentId);
    }
    if (this.includeIncorporationArticles) {
      documentIds.push(this.articlesOfIncorporationDocument?.documentId);
    }
    this.sendDocumentsForm.controls['folderIds'].patchValue(folderIds);
    this.sendDocumentsForm.controls['documentIds'].patchValue(documentIds);
    this.documentService.sendDocuments(this._entity?.entityId, this.sendDocumentsForm.value).subscribe((res) => {
      this.loading = false;
      this.sendDocumentsForm.reset();
      this.includeEinVerification = false;
      this.includeIncorporationArticles = false;
      this.onClose.emit();
    })
  }

  validateEmail(e): void{
    const control = new FormControl(e.value.trim(), Validators.email);
    // @ts-ignore
    if (control.errors?.email) {
      let currentEmails = this.sendDocumentsForm.controls["emailAddresses"].value;
      currentEmails.pop();
    }
  }

}
