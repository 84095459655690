import {Component, inject} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {PanelMenuModule} from "primeng/panelmenu";
import {BadgeModule} from "primeng/badge";
import {DatePipe, NgIf} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {AddEditEntityComponent} from "./components/add-new-entity/add-edit-entity.component";
import {HeaderComponent} from "./components/shared/header/header.component";
import {EntitiesHomeComponent} from "./components/entities-home/entities-home.component";
import {SideMenuComponent} from "./components/side-menu/side-menu.component";
import {Auth, authState, User} from "@angular/fire/auth";
import {Subscription, takeUntil} from "rxjs";
import {ToastModule} from "primeng/toast";
import {LoaderComponent} from "./components/shared/loader/loader.component";
import {AccountService} from "./services/account.service";
import {MessagesModule} from "primeng/messages";
import {UnsubscriberService} from "./services/unsubscriber.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [BadgeModule, FormsModule, PanelMenuModule, NgIf, RouterOutlet, AddEditEntityComponent, HeaderComponent, EntitiesHomeComponent, SideMenuComponent, ToastModule, LoaderComponent, MessagesModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  providers: [UnsubscriberService]
})
export class AppComponent {
  private auth = inject(Auth);
  private accountService = inject(AccountService);
  private unsubService = inject(UnsubscriberService);
  authState$ = authState(this.auth);
  authStateSubscription: Subscription;
  isLoggedIn: any = null;
  showLoader = false;
  products = [
    {
      code: "Little Diggers",
      name: "LLC",
      category: "4/11/24",
      quantity: "83-12312"
    }
  ]

  constructor(private router: Router) {
    this.authStateSubscription = this.authState$.pipe(this.unsubService.takeUntilDestroy).subscribe((aUser: User | null) => {
      //handle auth state changes here. Note, that user will be null if there is no currently logged in user.
      this.isLoggedIn = aUser;
      this.accountService.activeAccount.pipe(this.unsubService.takeUntilDestroy).subscribe((account) => {
        if (!account && this.isLoggedIn) {
          this.showLoader = true;
        } else {
          this.showLoader = false;
        }
      })
    });
  }

}
