import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {map, Observable, of, Subject, BehaviorSubject, subscribeOn} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Entity, EntityCreateRequest, EntityUpdateRequest } from '../models/entity.model';
import {
  DocumentUpdateRequest,
  DocumentUploadRequest,
  Folder,
  FolderCreateRequest,
  FolderUpdateRequest, SendDocumentRequest
} from "../models/document.model";
import {doc} from "@angular/fire/firestore";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private apiPrefix = "documents";
  private apiUrl = `${environment.apiUrl}/${this.apiPrefix}`;
 // public entities: BehaviorSubject<Entity[]> = new BehaviorSubject<Entity[]>([]);

  constructor(private http: HttpClient) {}

  // Create a new folder
  createFolder(folder: FolderCreateRequest): Observable<Folder> {
    return this.http.post<{data: Folder}>(this.apiUrl + "/folders", folder)
      .pipe(
        map(response => response.data),
        catchError(this.handleError<Folder>('createFolder'))
      );
  }

  deleteDocument(entityId, documentId) {
    return this.http.delete<void>(`${this.apiUrl}/${documentId}?entityId=${entityId}`)
      .pipe(
        catchError(this.handleError<void>('deleteDocument'))
      );
  }

  deleteFolder(entityId, folderId) {
    return this.http.delete<void>(`${this.apiUrl}/folders/${folderId}?entityId=${entityId}`)
      .pipe(
        catchError(this.handleError<void>('deleteFolder'))
      );
  }

  downloadDocument(entityId, documentId) {
    return this.http.get<{data: String}>(`${this.apiUrl}/${documentId}/download?entityId=${entityId}`, {responseType: 'blob' as 'json'})
      .pipe(
        catchError(this.handleError<Folder>('downloadDocument'))
      );
  }

  downloadFolder(entityId, folderId) {
    return this.http.get<{data: String}>(`${this.apiUrl}/folders/${folderId}/download?entityId=${entityId}`, {responseType: 'blob' as 'json'})
      .pipe(
        catchError(this.handleError<Folder>('downloadFolder'))
      );
  }

  sendDocuments(entityId, data: SendDocumentRequest): Observable<any> {
    return this.http.post<{data: any}>(this.apiUrl + `/send?entityId=${entityId}`, data)
      .pipe(
        map(response => response.data),
        catchError(this.handleError<Folder>('sendDocuments'))
      );
  }

  updateDocument(entityId, documentId, updates: DocumentUpdateRequest) {
    return this.http.put<{data: Document}>(`${this.apiUrl}/${documentId}?entityId=${entityId}`, updates)
      .pipe(
        map(response => response.data),
        catchError(this.handleError<Document>('updateDocument'))
      );
  }

  updateFolder(entityId, folderId, updates: FolderUpdateRequest) {
    return this.http.put<{data: Folder}>(`${this.apiUrl}/folders/${folderId}?entityId=${entityId}`, updates)
      .pipe(
        map(response => response.data),
        catchError(this.handleError<Folder>('updateFolder'))
      );
  }


  uploadDocuments(uploadRequest: DocumentUploadRequest): Observable<Document[]> {
    const formData = new FormData();
    formData.append("entityId", String(uploadRequest.entityId));
    if (uploadRequest.folderAssignments) {
      formData.append("folderAssignments", JSON.stringify(uploadRequest.folderAssignments));
    }
    if (uploadRequest.documentTypeAssignments) {
      formData.append("documentTypeAssignments", JSON.stringify(uploadRequest.documentTypeAssignments));
    }
    Array.from(uploadRequest.documents).forEach((file) => {
      formData.append('documents', file, file.name)
    });
    console.log("FORM DATA", formData.get("documentTypeAssignments"));
    return this.http.post<{data: Document[]}>(this.apiUrl, formData, {
      reportProgress: true
    }) // trigger header for the intercepter
      .pipe(
        map(response => response.data),
        catchError(this.handleError<Document[]>('uploadDocuments'))
      );
  }

  // Handle HTTP operation that failed.
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      return of(result as T);
    };
  }

}
