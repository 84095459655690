<div id="bgnd" class="w-full h-full bg-gray-50">
  <div class="flex flex-column align-items-center pt-8">
    <h1>Felix Secure Link</h1>
    <p-card [style]="{ width: '520px' }">
      <div *ngIf="!expiredLink">
        <p>{{secureLinkDetails?.entity!.name}} has shared the information below with you.</p>
        <p-divider></p-divider>
        <h3 class="text-primary">Business Details</h3>
        <div class="w-12">
          <div *ngIf="secureLinkDetails?.entity?.name" class="flex align-items-center justify-content-between">
            <p class="font-bold mr-3">Legal Name</p>
            <div class="flex align-items-center">
              <p>{{secureLinkDetails?.entity?.name}}</p>
              <p-button icon="pi pi-copy" [rounded]="true" [cdkCopyToClipboard]="secureLinkDetails?.entity?.name!" [text]="true" />
            </div>
          </div>
          <div *ngIf="secureLinkDetails?.entity?.entityType" class="flex align-items-center justify-content-between">
            <p class="font-bold mr-3">Entity Type</p>
            <div class="flex align-items-center">
              <p>{{secureLinkDetails?.entity?.entityType}}</p>
              <p-button icon="pi pi-copy" [rounded]="true" [cdkCopyToClipboard]="secureLinkDetails?.entity?.entityType!" [text]="true" />
            </div>
          </div>
          <div *ngIf="secureLinkDetails?.entity?.ein" class="flex align-items-center justify-content-between">
            <p class="font-bold mr-3">EIN</p>
            <p-inplace>
              <ng-template pTemplate="display">
                <span class="p-icon pi pi-eye mr-2"></span>
                <span>Show EIN</span>
              </ng-template>
              <ng-template pTemplate="content">
                <div class="flex align-items-center">
                  <span>
                      {{secureLinkDetails?.entity?.ein}}
                  </span>
                  <p-button icon="pi pi-copy" [rounded]="true" [cdkCopyToClipboard]="secureLinkDetails?.entity?.ein!" [text]="true" />
                </div>
              </ng-template>
            </p-inplace>
          </div>
          <div *ngIf="secureLinkDetails?.entity?.incorporationDate" class="flex align-items-center justify-content-between">
            <p class="font-bold mr-3">Incorporation Date</p>
            <div class="flex align-items-center">
              <p class="mr-1">{{secureLinkDetails?.entity?.incorporationDate | date : 'M/dd/yyyy'}}</p>
  <!--            <p-button icon="pi pi-copy" [rounded]="true" [cdkCopyToClipboard]="secureLinkDetails?.entity?.incorporationDate?.!" [text]="true" />-->
            </div>
          </div>
          <div *ngIf="secureLinkDetails?.entity?.incorporationState" class="flex align-items-center justify-content-between">
            <p class="font-bold mr-3">Incorporation State</p>
            <div class="flex align-items-center">
              <p class="mr-1">{{secureLinkDetails?.entity?.incorporationState}}</p>
              <p-button icon="pi pi-copy" [rounded]="true" [cdkCopyToClipboard]="secureLinkDetails?.entity?.incorporationState!" [text]="true" />
            </div>
          </div>
          <div *ngIf="secureLinkDetails?.entity?.address?.street" class="flex align-items-start mb-3 justify-content-between">
            <p class="font-bold mr-3">Address</p>
            <div class="flex align-items-start">
              <div class="text-right">
                <p>{{secureLinkDetails?.entity?.address?.street}}</p>
                <p>{{secureLinkDetails?.entity?.address?.city}}, {{secureLinkDetails?.entity?.address?.state}} {{secureLinkDetails?.entity?.address?.postalCode}}</p>
              </div>
              <p-button styleClass="mt-1" icon="pi pi-copy" [rounded]="true" [cdkCopyToClipboard]="'92-3422343'" [text]="true" />
            </div>
          </div>
        </div>
        <p-divider></p-divider>
        <h3 class="text-primary">Documents</h3>
        <div *ngFor="let doc of secureLinkDetails?.documents" class="flex justify-content-between align-items-center">
          <p>
            {{doc.name}}
            ({{doc.size | fileSize}})
          </p>
          <p-button (onClick)="downloadDocument(doc)" icon="pi pi-download"></p-button>
        </div>
      </div>
      <div *ngIf="expiredLink">
        <div class="flex flex-column align-items-center justify-content-center">
          <p class="pi pi-calendar-clock text-center mb-1 text-black-alpha-60" style="font-size: 6rem;"></p>
          <h3 class="text-black-alpha-60 mb-8">This link has expired.</h3>
        </div>
      </div>
    </p-card>
    <p *ngIf="!expiredLink" class="text-primary mt-4">This is a secure link generated by Felix App and will expire {{secureLinkDetails?.expirationDate | date: 'MMMM dd, yyyy'}}.</p>
  </div>
</div>
