import {Entity} from "./entity.model";
import {SecureLink} from "./securelink.model";

export interface Document {
  documentId: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  Entity:    Entity;
  entityId:  number;
  folderId:  number;
  url:       string;
  size:      number;
  mimeType:  string;
  folder:    Folder;
  documentType: DocumentType;
  magicLinks: SecureLink[];
}

export interface DocumentSendLog {
  entityId: number;
  entityName: string;
  sentBy: string;
  documentNames: string[];
  folderNames: string[],
  destinationEmails: string[];
}

export interface Folder {
  id: string;
  name: string;
  parentFolderId?: number;
  parentFolder?: Folder;
  subFolders?: Folder[];
  entity: Entity;
  documents?: Document[];
  magicLinks: SecureLink[];
  updatedAt: Date;
  createdAt: Date;
}

export enum DocumentType {
  GENERAL = "GENERAL",
  INCORPORATION_ARTICLES = "INCORPORATION_ARTICLES",
  EIN_VERIFICATION = "EIN_VERIFICATION"
}

export interface DocumentUploadRequest {
  documents: File[];
  entityId: number;
  folderAssignments?: [string: number][];
  documentTypeAssignments?: [string: string][];
}

export interface FolderCreateRequest {
  name: string;
  entityId: number;
  parentFolderId?: number;
}

export interface DocumentUpdateRequest {
  name?: string;
  folderId?: number;
}

export interface FolderUpdateRequest {
  name?: string;
  parentFolderId?: number;
}

export interface SendDocumentRequest {
  emailAddresses: string[];
  folderIds?: number[];
  documentIds?: number [];
  smessage?: string;
}
