import {Component, inject, OnInit} from '@angular/core';
import {BadgeModule} from "primeng/badge";
import {DatePipe, NgIf} from "@angular/common";
import {PanelMenuModule} from "primeng/panelmenu";
import {PrimeTemplate} from "primeng/api";
import {EntityService} from "../../services/entity.service";
import {EntityHomeDefaultView} from "../../models/entity.model";
import {Router} from "@angular/router";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {DividerModule} from "primeng/divider";
import {ProgressBarModule} from "primeng/progressbar";
import {AccountService} from "../../services/account.service";
import {UnsubscriberService} from "../../services/unsubscriber.service";
import {Subscription, SubscriptionStatus} from "../../models/subscription.model";
import {getRemainingDays} from "../../utils/helpers";
import {Button} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {InputTextModule} from "primeng/inputtext";
import {TreeSelectModule} from "primeng/treeselect";
import {takeUntil} from "rxjs";
import {FelixUser} from "../../models/user.model";
import {AuthService} from "../../services/auth.service";
import {Account, AccountRole} from "../../models/account.model";

@Component({
  selector: 'app-side-menu',
  standalone: true,
  providers: [UnsubscriberService],
  imports: [
    BadgeModule,
    NgIf,
    PanelMenuModule,
    PrimeTemplate,
    DropdownModule,
    FormsModule,
    DividerModule,
    ProgressBarModule,
    DatePipe,
    Button,
    DialogModule,
    InputTextModule,
    TreeSelectModule
  ],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.css'
})
export class SideMenuComponent implements OnInit {
  private unsubService = inject(UnsubscriberService);
  accountDropdownValue;
  activeAccount: Account | undefined;
  showAddAccountDialog = false;
  newAccountName = "";
  accountRoles: AccountRole[] | any = [];
  items = [
    {
      label: 'Entities',
      icon: 'pi pi-building',
      badge: '',
      items: [
        {
          label: 'Entities Home',
          icon: 'pi pi-home',
          command: () => {
            this.goToEntityList();
          }
        },
        {
          label: 'Entity Map',
          icon: 'pi pi-sitemap',
          command: () => {
            this.goToEntityMap();
          }
        },
        {
          label: 'New Entity',
          icon: 'pi pi-plus',
          command: () => {
            this.goToNewEntity();
          }
        }
      ]
    },
    {
      label: 'Links & Documents',
      icon: 'pi pi-link',
      items: [
        {
          label: 'Secure Links',
          icon: 'pi pi-link',
          routerLink: 'secure-links'
        },
        {
          label: 'Sent Documents',
          icon: 'pi pi-list',
          routerLink: 'sent-documents'
        }
      ]
    },
    {
      label: 'Settings',
      icon: 'pi pi-cog',
      items: [
        {
          label: 'Profile',
          icon: 'pi pi-user',
          routerLink: "settings",
          queryParams: {'tab': 'profile'}
        },
        {
          label: 'User Management',
          icon: 'pi pi-users',
          routerLink: "settings",
          queryParams: {'tab': 'users'}
        },
        {
          label: 'Billing',
          icon: 'pi pi-receipt',
          routerLink: "settings",
          queryParams: {'tab': 'billing'}
        },
      ]
    }
  ];
  subscription: Subscription | undefined;
  createAccountLoading = false;

  constructor(private entityService: EntityService, private router: Router, private accountService: AccountService, private authService: AuthService) {}

  ngOnInit() {
    this.accountService.activeAccount.pipe(this.unsubService.takeUntilDestroy).subscribe((account) => {
      if (account) {
        debugger;
        this.activeAccount = account;
        this.accountDropdownValue = this.accountRoles.find(a => a.accountId === account.accountId);
        if (account.subscriptions.length) {
          this.subscription = account.subscriptions.find((s) => s.status !== SubscriptionStatus.CANCELED);
        }
      }
    })
    this.authService.userDetails.pipe(this.unsubService.takeUntilDestroy).subscribe((user?: FelixUser) => {
      if (user) {
        this.accountRoles = user.accountRoles;
        this.accountRoles.push({account: {name: "Create New Account"}, accountId: 0})
      }
    });
  }

  goToEntityMap() {
    this.entityService.setDefaultEntityHomeView(EntityHomeDefaultView.map);
    this.router.navigate(["/home"], {queryParams: {'view':'map'}})
  }

  goToEntityList() {
    this.entityService.setDefaultEntityHomeView(EntityHomeDefaultView.list);
    this.router.navigate(["/home"], {queryParams: {'view':'list'}})
  }

  goToNewEntity() {
    this.router.navigate(["/home"], {queryParams: {'view':'new'}})
  }

  createNewAccount() {
    this.createAccountLoading = true;
    this.accountService.createAccount(this.newAccountName).subscribe((account) => {
      if (account) {
        this.authService.getUserDetails().subscribe((user) => {
          this.accountService.setActiveAccount(account);
          this.newAccountName = '';
          this.createAccountLoading = false;
          this.showAddAccountDialog = false;
        })
      }
    })
  }

  selectNewAccount(event) {
    if (event.value.accountId !== 0) {
      this.accountService.getAccount(event.value.accountId);
    }
  }

  resetAccount() {
    this.accountDropdownValue = this.accountRoles.find(a => a.accountId === this.activeAccount?.accountId);
  }

  protected readonly getRemainingDays = getRemainingDays;
}
